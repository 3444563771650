import React from "react";
const Submitbtn = ({ properties: { label } , onClick }) => {
    return (
        <div className="flex items-center justify-center hover:opacity-80 bg-[#000] text-[#624592] w-[100%] h-[35px] mt-[20px] transition-opacity duration-300">
            <button className="font-semibold w-full"
            type="submit"
            onClick={onClick}
            >{ label }</button>
        </div>
    )
}



const SingUpGoogle = ({properties:{label},onClick}) => {
    return (
        <button className="w-[100%] text-sm h-8 self-center border-[1px] border-[#B6B6B6] border-solid text-black" type="submit" onClick={onClick}>{label}
        
            <span className="ml-2 text-[#0198FF]">G</span>
            <span className="text-[#FF0000]">o</span>
            <span className="text-[#FFAC2F]">o</span>
            <span className="text-[#0198FF]">g</span>
            <span className="text-[#0198FF]">l</span>
            <span className="text-[#FF0000]">e</span>

            </button>
    )
}


export {
    Submitbtn,
    SingUpGoogle
} 