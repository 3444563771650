import React, { useState } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import navLogo from "../assets/navBarLogo.png";
import { Menu, Close } from "react-ionicons";


const Navbar = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(true);
    const [active, setActive] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
      };
    
    const handleActiveLink = () => {
        setActive(true);
    }
    const navigateHome = () => {
        navigate("/");
        setActive(false);
    }

    return (
        <nav className='bg-[#3f2b7b] top-0 md:h-[68px] w-full fixed z-50 h-[65px]'>
            <div className="flex flex-row md:flex-row justify-between bg-[#fff] md:pl-[110px] px-[20px] w-full h-[95%] md:pr-0 md:h-[65px]">
                <div className="flex flex-row justify-center md:justify-normal mt-2 md:my-auto items-center md:items-start md:w-[160px]">
                    <div className="flex flex-row my-[5px] justify-center">
                        <img  onClick={navigateHome}
                        className="w-[50px] h-[45px] my-auto rounded md:ml-auto cursor-pointer" src={navLogo}/>
                        <span className="text-white font-extrabold tracking-wider my-auto ml-[10px] text-[22px]"> RADICI </span>
                    </div>
                </div>
                <span className="md:hidden block ml-[40vw] mt-[4vh] w-[40px]" onClick={ toggleNavbar } >
                    {
                        isOpen ? <Menu /> : <Close />
                    }
                </span>
                <div id="desktop" className="hidden md:flex flex-row pr-10">
                    <ul className="flex flex-row justify-around items-center w-full">
                        <li>
                            <NavLink to='/aboutus'><button onClick={handleActiveLink} className={`${active==true ? "border-b-2 border-purple-700": ""} font-semibold text-[19px] text-[#000] transition hover:text-[#3f2b7b] duration-500 ease-in-out w-[150px] h-[40px]`}
                            > About Us </button></NavLink>
                        </li>
                        <li>
                            <NavLink to='/signin'><button className='font-semibold text-[19px] text-[#000] transition hover:text-[#3f2b7b] duration-500 ease-in-out w-[150px] h-[40px]'
                            > Sign In </button></NavLink>
                        </li>
                        <li>
                            <NavLink to='/signup'><button className='font-semibold text-[19px] text-[#000] transition hover:text-[#3f2b7b] duration-500 ease-in-out w-[150px] h-[40px] mr-12'
                            > Sign Up </button></NavLink>
                        </li>
                        <li>
                            <NavLink to='/waitlist'><button className='text-[#fff] rounded-[5px] w-[150px] h-[40px] my-auto font-bold hover:text-[#000] bg-[#3f2b7b] transition duration-500 ease-in-out'
                            > Join Waitlist </button></NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="mobile view" className="md:hidden flex justify-end">
                {
                    !isOpen && (
                        <div className="md:hidden flex flex-row justify-center w-[45%] h-full bg-[#3f2b7b] items-center mb-5 rounded-b-[5px]  py-[10px]">
                            <ul className="flex flex-col md:flex-row justify-center md:justify-between items-center gap-5">
                                <li>
                                    <NavLink to='/aboutus'><button className="font-semibold text-[19px] md:w-[100px] text-[#fff]"
                                    > About Us </button></NavLink>
                                </li>
                                <li>
                                    <NavLink to='/signin'><button className="font-semibold text-[19px] md:w-[100px] text-[#fff]"
                                    > Sign In </button></NavLink>
                                </li>
                                <li>
                                    <NavLink to='/signup'><button className="font-semibold text-[19px] md:w-[100px] text-[#fff]"
                                    > Sign Up </button></NavLink>
                                </li>
                                <li>
                                    <NavLink to='/waitlist'><button className="bg-[#000] rounded-[5px] w-[150px] h-[40px] my-auto font-bold text-[#fff]"
                                    > Join Waitlist </button></NavLink>
                                </li>
                            </ul>
                        </div>
                    )
                }
            </div>
        </nav>
    )
}

export default Navbar;
