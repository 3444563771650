import React, { useState } from "react";
import { Submitbtn } from "./components/buttons";
import RememberMeReset from "./components/rememberMeReset";
import PasswordField from "./components/passwordField";
import LogoImg from "./assets/Logo.png";


const CreateNewPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')

    return (
        <div>
            <div className="flex flex-col-reverse md:flex-row p-5">
            <div className="flex justify-center md:h-0 md:absolute md:top-[115%] md:left-[10%] md:w-[15%]">
            <div className="md:absolute md:-left-[40%] md:-top-[45vh] md:p-3 lg:relative">
                <img className="w-[100px] md:w-[190px]" src={ LogoImg } />
            </div>
            </div>
            <div className="flex flex-col justify-center items-center mx-auto w-[80%] md:w-[30%] mt-[100px] pb-[40px]">
                <div className="text-center text-3xl mb-4 createPassword"> Create New Password </div>
                <p className="text-center mb-[70px] "> Please enter your new password </p>
                <span className="w-full mb-8"> <PasswordField properties={{label:"New Password"}}/> </span>
                <PasswordField properties={{label:"Confirm Password"}}/>
                <Submitbtn properties={{ label: 'Reset Password' }} />
                <RememberMeReset/>
            </div>
        </div>
        </div>
    )
}


export default CreateNewPassword;