import React, { useState } from "react";
import InputTextField from "./components/inputTextField";
import { Checkbox } from "flowbite-react";
import { Submitbtn } from "./components/buttons";
import SelectCountry from "./components/SelectCountry";
import waitlistImg from "./assets/waitlistimg.svg";
import successlogo from "./assets/successlogo.svg";
import waitlistbgmobile from "./assets/waitlistbgmobile.png";

const WaitlistPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);

    const handleJoinWaitlist = (event) => {
        event.preventDefault(); //
        setIsModalOpen(true);
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
        if (event.target.value !== selectedRole) {
            const otherCheckboxId = selectedRole === 'contractor' ? 'builder' : 'contractor';
            document.getElementById(otherCheckboxId).checked = false;
        }
    };
    
      

    return (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:m-[40px] md:mx-[20vw] overflow-hidden">
            <div className="md:w-[50%] h-[90vh] mx-auto relative">
            {/* <div className="hidden md:block text-[26px] font-bold text-white text-center absolute top-72 md:left-14 w-[50vh]"> Let's build your dream home together </div> */}
                {/* <img 
                className="hidden md:block w-[90vw] h-[90vh] -z-50"
                src={waitlistbgImg} alt="Waiting for image" /> */}
                <img 
                className="w-[90vw] h-[70vh] md:h-[90vh] pt-10 md:pt-0"
                src={waitlistbgmobile} alt="Waiting for image" />
            </div>
            <div className="flex flex-col md:w-[50%] justify-center">
                <h1 className="text-[22px] md:text-[30px] mx-auto font-semibold font-serif font-times md:pt-7 text-center"> Join the Waitlist for Radici </h1>
                <text className="text-[#46257A] text-center pb-7 text-[13px] md:text-[15px]"> Radici will be available soon </text>
                <span className="text-[13px] md:text-[13px] mx-4 md:mx-3 text-center mb-2"> Provide us with your credentials below to be kept abreast with updates </span>
                <div className="px-[20px] gap-y-12">
                    <form action="" className=" space-y-3">
                    <InputTextField label="Full Name:" type={"text"} />
                    <InputTextField label="Email" type={"email"} />
                    <InputTextField label="Phone Number" type={"tel"}/>
                    <SelectCountry />
                    <div className='flex justify-between items-center w-[100%] pt-4'>
                        <div className='checkItem'>
                            <label htmlFor="Join as Contrator" className="text-xs pr-2">Join as Contractor</label>
                            <Checkbox
                                id="contractor"
                                inputId="contractor"
                                name="role"
                                value="contractor"
                                checked={selectedRole === 'contractor'}
                                onChange={handleRoleChange}
                            />
                            <div className="text-[#46257A] text-[12px]"> Someone who wants to contract houses </div>
                        </div>
                        <div className='checkItem'>
                            <span className="flex flex-row justify-end items-end">
                                <label htmlFor="Join as Client" className="text-xs pr-2">Join as Client</label>
                                <Checkbox
                                    id="builder"
                                    inputId="builder"
                                    name="role"
                                    value="builder"
                                    checked={selectedRole === 'builder'}
                                    onChange={handleRoleChange}
                                />
                            </span>
                            <div className="text-[#46257A] text-[12px] text-right"> Someone who wants their home build </div>
                        </div>
                    </div>
                    <div>
                        <Submitbtn properties={{label: "Join Waitlist"}} onClick={handleJoinWaitlist} />
                    </div>
                    </form>
                </div>
            </div>
            {isModalOpen && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
                    <div className="flex flex-col justify-center items-center w-[90%] md:w-[30%] lg:w-[30%] bg-white py-4 md:p-8 lg:p-8 rounded-lg shadow-md">
                        <img 
                        className="w-[100px] pb-4"
                        src={successlogo} alt="success" />
                        <text className="text-[#46257A] text-[18px] md:text-[30px] lg:text-[30px] text-center leading-8 w-[23rem] pb-4 px-8"> You have been added to the waiting list </text>
                        <img src={waitlistImg} alt="waitlist image" className="pb-4" />
                        <text className="text-center"> We'll let you know when Radici is ready </text>
                        <button className="mt-4 bg-[#be99fa] hover:bg-blue-600 text-black px-4 py-2 rounded-md" onClick={() => setIsModalOpen(false)}>Done</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default WaitlistPage;