import React,{useState} from 'react'

import Search from './components/search'
// import DateRangePicker from './date'



const ContractRequest = ()=>{

    const [location, setLocation] = useState('')
    const [startDate, setStartDate] = useState('')
    const [duration, setDuration] = useState('')


    const handleLocation = (e) => {
        setLocation(e.target.value)
    }

    const handleStartDate = (e) =>{
        setStartDate(e.target.value)
    }

    const handleDuration = (e) =>{
        setDuration(e.target.value)
    }

    return(
        <div>
            
            <div className='flex flex-row justify-between mr-[30px]'>
                <p className="pt-[100px] text-[26px] px-[30px] py-[10px] font-bold">Create Contract Requests</p>
                <p className='text-[18px] font-bold'>Step 1</p>
            </div>
            <p className="text-[#003B63] px-[30px] font-bold text-xl">Contract Details</p>
            <div className='bg-[#46257A26] w-[1460px] h-[300px] ml-[30px] mr-[30px]'>
                <div className='flex flex-row justify-between'>
                    <div className='flex flex-col space-y-[30px] ml-[30px]  justify-around '>
                        <label className='text-[#00000099] text-[14px] mt-[30px]' >Phone Number*</label>
                                <div>
                                    <div >
                                        <input
                                            className='outline-none border-none h-[40px] w-[320px] md:h-[40px] md:w-[380px]  rounded-md'
                                            type="text"
                                            value={location} 
                                            onChange={handleLocation}
                                        />


                                    </div>

                                </div>
                        {/* <DateRangePicker/> */}
                        <label className='text-[#00000099] text-[14px] mt-[30px]' >Start Date*</label>
                                <div>
                                    <div >
                                        <input
                                            className='outline-none border-none h-[40px] w-[320px] md:h-[40px] md:w-[380px]  rounded-md'
                                            type="text"
                                            value={startDate} 
                                            onChange={handleStartDate}
                                        />


                                    </div>

                                </div>

                        

                    </div>
                    <div className='mr-[50px] mt-[30px]'>
                        <label className='text-[#00000099] text-[14px] mt-[30px]' >Duration*</label>
                            <div>
                                <div >
                                    <input
                                        className='outline-none border-none h-[40px] w-[320px] md:h-[40px] md:w-[380px]  rounded-md'
                                        type="text"
                                        value={duration} 
                                        onChange={handleDuration}
                                    />


                                </div>

                            </div>
                    </div>
                </div>

            </div>
            <div className='fixed bottom-0 left-0 right-0 md:px-[30px] h-[60px] flex flex-row w-full md:w-full justify-end items-center space-x-8'>
                <button className='bg-purple-200 px-[24px] py-[10px] rounded-md'> Cancel </button>
                <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> Save </button>
            </div>



        </div>
    );
}

export default ContractRequest