import React from 'react';

import john from './assets/john.svg'
import document from './assets/document.svg'
import ProfileTable from './components/ProfileTable'


const ClientProfile = ()=>{
    return(
        <div>    
            <p className="pt-[100px] text-[26px] px-[30px] py-[10px] font-bold">My Profile</p>
            <div className="border border-red-[#CEB0FF] border-solid border-1 mx-[30px] ">
                <div className="border-b border-red-[#CEB0FF] border-solid border-1 mx-[30px] h-[30vh]">
                <div className='flex flex-row  w-full '>
                    <div className="border-r border-red-[#CEB0FF] w-[500px]">
                        <p className='font-bold text-xl mt-[10px]'> Stephen Appiah</p>
                        <div className='flex flex-row space-x-[40px] mt-[20px]'>
                            <img src={john} className="w-[150px]"/>
                            <img src={document} className="w-[100px]"/>
                        </div>
                    </div>
                    <div className="border-r border-red-[#CEB0FF] flex flex-col justify-center items-center w-[500px]">
                        <div className='flex flex-col items-start space-y-[20px]'>
                            <p>Email:<span className='text-blue-500 p-[30px]'>ericakyei@gmail.com</span></p>
                            <p>Contact:<span className='p-[30px]'>023040040</span></p>
                            <p>Country:<span className='p-[30px]'>Ghana</span></p>
                            <p>Address:<span className='text-blue-500 p-[30px]'>Accra</span></p>

                        </div>
                        
                    </div>
                    <div className=" flex flex-col justify-center items-center w-[500px]">
                        <div className='flex flex-col items-start space-y-[20px]'>
                            <p>Account Type:<span className='p-[30px]'>Client</span></p>
                            <p>Account Status:<span className='p-[30px]'>Active</span></p>
                            <p>Account Created:<span className='p-[30px]'>12th May, 2021</span></p>
                        </div>
                        
                    </div>
                </div>

                <div className=' mt-[60px]'>
                    <p className='text-xl font-bold text-[#003B63] '>Contracts</p>
                    <ProfileTable/>
                </div>
                <div className=' md:px-[30px] mt-[20px] h-[60px] flex flex-row w-full md:w-full justify-end items-center space-x-8'>
                    <button className='bg-purple-200 px-[24px] py-[10px] rounded-md'> Back </button>
                    <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> Export</button>
                </div>

                


                </div>

            </div>

        </div>
    );
}

export default ClientProfile;