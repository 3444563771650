import React from "react";
import { Outlet } from "react-router-dom";
import { SignedInNavbar } from "./navigations";

const ContractorMainLayout = () => {
    return (
        <>
            <div>
                <SignedInNavbar />
            </div>
            <div>
                <Outlet />
            </div>
        </>
    )
}


export default ContractorMainLayout;