import react, {useState} from 'react';
import { Dropdown } from 'primereact/dropdown';
import ParentNav from './components/navigations';
import search from './assets/search.svg';
import file from './assets/file.svg';
import threedots from './assets/threebots.svg';


const ProjectReminders = () => {
    const [sorted, setSorted] = useState('');
    const [selectedCity, setSelectedCity] = useState(null);

    const cities = [
        { name: "Client ID" },
        { name: "Location" },
        { name: "Start Date" },
        { name: "Duration" }
    ];

    const handleSortbyChange = (e) => {
        setSorted(e.target.value);
    };


    const data = [
        { contractorId: '001', lastReminded:{date: '2023-01-01', time: '00:00:00'}, timesReminded: 3, lastReportDate: '2023-01-01'},
        { contractorId: '002', lastReminded:{date: '2023-01-01', time: '00:00:00'}, timesReminded: 2, lastReportDate: '2023-02-01'},
        { contractorId: '003', lastReminded:{date: '2023-01-01', time: '00:00:00'}, timesReminded: 1, lastReportDate: '2023-01-01'},
        { contractorId: '004', lastReminded:{date: '2023-01-01', time: '00:00:00'}, timesReminded: 1, lastReportDate: '2023-02-01'},
        { contractorId: '005', lastReminded:{date: '2023-01-01', time: '00:00:00'}, timesReminded: 4, lastReportDate: '2023-02-01'},
        { contractorId: '006', lastReminded:{date: '2023-01-01', time: '00:00:00'}, timesReminded: 3, lastReportDate: '2023-02-01'},
        { contractorId: '007', lastReminded:{date: '2023-01-01', time: '00:00:00'}, timesReminded: 5, lastReportDate: '2023-02-01'}
    ];

    return (
        <div>
            {/* <ParentNav /> */}
            <div className='pt-[14vh] px-[50px]'>
                <text className="text-[25px] font-extrabold w-full bg-white"> Contracts </text>
            </div>
            <div className='flex flex-row h-[68vh] px-[30px]'>
              <div className='w-[30%] h-full bg-[#DAD3E4]'>
                <h1> Calender </h1>
              </div>
              <div className='w-[70vw]'>
                  <table className="w-full bg-white border border-gray-300 mb-[10vh] h-full">
                      <thead className='bg-[#c0b4cc]'>
                          <tr>
                              <th className="py-2 px-4 border-b">Contractor's ID</th>
                              <th className="py-2 px-4 border-b">Last Reminded at</th>
                              <th className="py-2 px-4 border-b">Times Reminded</th>
                              <th className="py-2 px-4 border-b">Last Report Date</th>
                          </tr>
                      </thead>
                      <tbody>
                          {data.map((item, index) => 
                          (
                          <tr key={index} className={index % 2 === 0 ? 'bg-[#fff]' : 'bg-[rgb(224,212,228)]'}>
                              <td className="py-1 px-4 text-center border-b">{item.contractorId}</td>
                              <td className="py-1 px-4 text-center border-b">
                                <div className='flex flex-col'>
                                  <text> {item.lastReminded.date} </text>
                                  <text className='text-[13px]'> {item.lastReminded.time} </text>
                                </div>
                                </td>
                              <td className="py-1 px-4 text-center border-b">{item.timesReminded}</td>
                              <td className="py-1 px-4 text-center border-b">{item.lastReportDate}</td>
                          </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
            </div>
            <div id='footer' className=' px-[30px] h-[60px] flex flex-row w-full fixed bottom-0 justify-between items-center space-x-8 bg-black'>
                <button className='bg-[#CEB0FF] px-[24px] py-[10px] rounded-md'> Previous </button>
                <button className='bg-[#CEB0FF] px-[16px] py-[10px] rounded-md'> 1 </button>
                <button className='bg-[#CEB0FF] text-black  px-[24px] py-[10px] rounded-md'> Next </button>
            </div>
        </div>
    )
}


export default ProjectReminders;