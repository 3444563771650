import React from 'react'
import { Password } from 'primereact/password';

export default function PasswordField({ label, value, setPasswordFunc, feedback }) {
    return (
        <div className='flex flex-col w-[100%]' >
            <label className='text-xs' htmlFor='password'>{label}</label>
            <Password className='h-9' id='password' feedback={feedback}  value={value} onChange={setPasswordFunc} toggleMask />
        </div>
    )
}
