import React, { useState } from 'react';
import profilePic from "./assets/profilepic.svg";
import doc from "./assets/doc.svg";
import file from "./assets/file.svg";

const FieldAgentProfile = () => {
    const data = [
        { contractId: '001', requestID: '12345', clientName: "FirstName Surname", contractorName: 'FirstName Surname', status: 'ongoing', timestamps: '123456789' },
        { contractId: '002', requestID: '12345', clientName: "FirstName Surname", contractorName: 'FirstName Surname', status: 'ongoing', timestamps: '987654321' },
        { contractId: '003', requestID: '12345', clientName: "FirstName Surname", contractorName: 'FirstName Surname', status: 'ongoing', timestamps: '123456789' },
        { contractId: '004', requestID: '12345', clientName: "FirstName Surname", contractorName: 'FirstName Surname', status: 'ongoing', timestamps: '987654321' },
        ];

    return (
        <div>
            <div className='flex flex-col px-[30px] pt-[14vh]'>
                <text className='text-[26px] font-bold'> My Profile </text>
            <div className='border'>
                <div className='flex flex-row justify-between h-[32vh]'>
                    <span className='border-r-2 w-[23%]'>
                        <text> Stephen Appiah </text>
                        <span className='flex flex-row'>
                            <img src={ profilePic } alt='' className='w-[13vw]'/>
                        </span>
                    </span>
                    <span className='flex flex-col justify-between px-6 py-7 border w-[38%]'>
                        <span>Email: <a>texst@gmail.com</a></span>
                        <span>Contact: 1234567890</span>
                        <span>Country: Ghana</span>
                        <span>Address: Tilak Ave, Accra</span>
                        <span> Role: Field Agent </span>
                    </span>
                    <span className='flex flex-col px-6 py-7 border w-[38%]'>
                        <span>Occupation: Mason</span>
                        <span className='mt-5'>Date enrolled: 20/03/2022</span>
                    </span>
                </div>
                <div>
                    <text className='text-[#003B63] p-5 font-bold text-[20px]'> Contracts: </text>
                    <table className="w-full bg-white border border-gray-300 mt-[10px] mb-[10vh]">
                        <thead className='bg-[#c0b4cc]'>
                            <tr>
                                <th className="py-2 px-4 h-12">Client ID</th>
                                <th className="py-2 px-4 h-12">Request ID</th>
                                <th className="py-2 px-4 h-12"> Contractor's Name</th>
                                <th className="py-2 px-4 h-12"> Client's Name</th>
                                <th className="py-2 px-4 h-12">Status</th>
                                <th className="py-2 px-4 h-12">Timestamps</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => 
                            (
                            <tr key={index} className={index % 2 === 0 ? 'bg-[#fff]' : 'bg-[#e0d4e4]'}>
                                <td className="py-2 px-4 text-center h-12">{item.contractId}</td>
                                <td className="flex justify-center py-2 h-12">{ item.requestID }</td>
                                <td className="py-2 px-4 text-center h-12">{item.clientName}</td>
                                <td className="py-2 px-4 text-center h-12">{item.contractorName}</td>
                                <td className="py-2 px-4 text-center h-12">{item.status}</td>
                                <td className="py-2 px-4 text-center h-12">{item.timestamps}</td>

                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            <div className=' px-[30px] h-[60px] flex flex-row fixed bottom-0 right-0 justify-end items-center space-x-8'>
                <button className='bg-purple-200 px-[24px] py-[10px] rounded-md'> Back </button>
                <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> Edit </button>
            </div>

        </div>

    );
};

export default FieldAgentProfile;
