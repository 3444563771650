import React, { useState } from 'react';
import browse from './assets/browse.jpg'



const countries = [
    'Country 1',
    'Country 2',
    'Country 3',
    // Add more countries as needed
];

const CompProf = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('');
    const [address, setAddress] = useState('');
    const [profilePicture, setProfilePicture] = useState(null);
    const [occupation, setOccupation] = useState('');
    const [pointOfContact, setPointOfContact] = useState('');
    const [contact, setContact] = useState('');
    const [id, setId] = useState('');
    const [documents, setDocuments] = useState(null);



    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        setProfilePicture(file);
    };

    const handleOccupationChange = (e) => {
        setOccupation(e.target.value);
    };

    const handlePointOfContactChange = (e) => {
        setPointOfContact(e.target.value);
    };

    const handleContactChange = (e) => {
        setContact(e.target.value);
    };

    const handleIdChange = (e) => {
        setId(e.target.value);
    };

    const handleDocumentsChange = (e) => {
        const file = e.target.files[0];
        setDocuments(file);
    };




    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform form submission logic here
    };

    return (
        <div>
            
            <div className='flex flex-col md:px-[30px] pt-[100px]'>
            {/* <Navbar/> */}
            <div className=' h-[80px] flex flex-col justify-between'>
                <p className='text-[26px] font-bold'>Complete profile</p>
                <p className='text-[14px] font-semibold text-[#00000099]'>Please fill the forms below</p>
            </div>

            <div className='flex md:flex-row flex-col justify-around items-center bg-[#46257A26] space-y-10 p-[30px] w-full md:p-[20px]'>
                <div className='flex flex-col space-y-30'>
                    <form onSubmit={handleSubmit} className='flex flex-col justify-between items-start  h-[420px]'>
                        <div className='flex flex-col '>
                            <label className='text-[#00000099] text-[14px]' >Phone Number*</label>
                            <div>
                                <div >
                                    <input
                                        className='outline-none h-[40px] w-[320px] md:h-[40px] md:w-[380px]  rounded-md'
                                        type="text"
                                        value={phoneNumber} 
                                        onChange={handlePhoneNumberChange}
                                    />


                                </div>

                            </div>

                        </div>
                        <div className='flex flex-col '>
                            <label className='text-[#00000099] text-[14px]'>Country of Residence*</label>
                            <select value={country} onChange={handleCountryChange} className='outline-none md:h-[40px]  h-[40px] w-[320px]  md:w-[380px] rounded-md'>
                                <option value=""></option>
                                {countries.map((country, index) => (
                                    <option key={index} value={country}>
                                        {country}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='flex  flex-col'>
                            <label className='text-[#00000099] text-[14px]' >Address*</label>
                            <div>
                                <div >
                                    <input
                                        className='outline-none  h-[40px] w-[320px]  md:h-[40px] md:w-[380px] rounded-md'
                                        type="text"
                                        value={address}
                                        onChange={handleAddressChange}
                                    />


                                </div>

                            </div>

                        </div>
                        <div className="relative">
                            <p className='text-[#00000099] text-[14px]'>Profile Profile* </p>
                            {/* <div className=' h-[90px] bg-white flex justify-end rounded-md'> */}
                                <label htmlFor="fileInput" className="cursor-pointer flex bg-white  md:w-[380px] rounded-md p-2 ">
                                    {profilePicture ? (
                                        <div className='flex justify-end  items-center md:w-[380px]'>
                                            <img
                                                src={URL.createObjectURL(profilePicture)}
                                                alt="Selected file"
                                                className="w-40 h-40 object-cover rounded-md"
                                            />

                                        </div>

                                    ) : (
                                        <div className=' flex justify-end  items-center w-[320px] md:w-[380px]'>
                                            <img
                                                src={browse}
                                                alt="Choose File"
                                                className="w-[110px]  object-cover"
                                            />

                                        </div>

                                    )}
                                </label>
                                <input
                                    id="fileInput"
                                    className="hidden"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleProfilePictureChange}
                                />

                            {/* </div> */} 


                        </div>
                    </form>
                </div>

                {/* form 2 */}
                <div className='flex flex-col space-y-30 '>
                    <form onSubmit={handleSubmit} className='flex flex-col justify-between items-start  h-[470px]'>
                        <div className='flex mx-auto flex-col'>
                            <label className='text-[#00000099] text-[14px]' >Occupation*</label>
                            <div>
                                <div >
                                    <input
                                        className='outline-none  h-[40px] w-[320px] md:h-[40px] md:w-[380px] rounded-md'
                                        type="text"
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                    />


                                </div>

                            </div>

                        </div>
                        <div className='flex flex-col mx-auto'>
                            <label className='text-[#00000099] text-[14px]' >Point Of Contact*</label>
                            <div>
                                <div >
                                    <input
                                        className='outline-none  h-[40px] w-[320px] md:h-[40px] md:w-[380px] rounded-md'
                                        type="text"
                                        value={contact}
                                        onChange={handleContactChange}
                                    />


                                </div>

                            </div>

                        </div>
                        <div className='flex mx-auto flex-col'>
                            <label className='text-[#00000099] text-[14px]' >Point number of point contact*</label>
                            <div>
                                <div >
                                    <input
                                        className='outline-none  h-[40px] w-[320px] md:h-[40px] md:w-[380px] rounded-md'
                                        type="text"
                                        value={address}
                                        onChange={handleAddressChange}
                                    />


                                </div>

                            </div>

                        </div>
                        <div className='flex mx-auto flex-col'>
                            <label className='text-[#00000099] text-[14px]' >ID type*</label>
                            <div>
                                <div >
                                    <input
                                        className='outline-none  h-[40px] w-[320px] md:h-[40px] md:w-[380px] rounded-md'
                                        type="text"
                                        value={id}
                                        onChange={handleIdChange}
                                    />


                                </div>

                            </div>

                        </div>
                        <div className="relative">
                            <p className='text-[#00000099] text-[14px]'>Profile Profile* </p>
                            {/* <div className=' h-[90px] bg-white flex justify-end rounded-md'> */}
                                <label htmlFor="fileInput" className="cursor-pointer flex bg-white  md:w-[380px] rounded-md p-2 ">
                                    {profilePicture ? (
                                        <div className='flex justify-end  items-center w-[320px] md:w-[380px]'>
                                            <img
                                                src={URL.createObjectURL(profilePicture)}
                                                alt="Selected file"
                                                className="w-40 h-40 object-cover rounded-md"
                                            />

                                        </div>

                                    ) : (
                                        <div className=' flex justify-end  items-center w-[320px] md:w-[380px]'>
                                            <img
                                                src={browse}
                                                alt="Choose File"
                                                className="w-[110px]  object-cover"
                                            />

                                        </div>

                                    )}
                                </label>
                                <input
                                    id="fileInput"
                                    className="hidden"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleProfilePictureChange}
                                />

                            {/* </div> */} 


                        </div>
                    </form>
                </div>
            </div>


            </div>
            <div className=' md:px-[30px] h-[60px] flex flex-row w-full md:w-full justify-end items-center space-x-8'>
                <button className='bg-purple-200 px-[24px] py-[10px] rounded-md'> Cancel </button>
                <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> Save </button>
            </div>

        </div>
        
        

    );
};

export default CompProf;
