import React from "react";

import DateRangePicker from './components/date'
import Search from './components/search'
import ProgressTable from "./components/ProgressTable";

const ProgressReport = () => {
    return (
        <div>
            <div className="pt-[100px]">
                <div className="flex flex-row justify-between">
                    <p className="text-[26px] px-[30px] py-[10px]"><spin className="font-bold pr-[10px]" >Progress reports for</spin>Contracts 12345</p>
                    <DateRangePicker/>
                    
                </div>
                <Search/>
                <ProgressTable/>
            </div>
        </div>
      
    );
}

export default ProgressReport;
