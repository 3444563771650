import React, { useState } from "react";
import { Dialog } from 'primereact/dialog';
import doc from "./assets/doc.svg";
import clickview from "./assets/clickview.svg";
import search from "./assets/search.svg";

const FieldAgentHome = () => {
    const [visible, setVisible] = useState(false);

    return (
        <div className="relative">
            <div className="flex flex-col px-[50px] h-screen pt-[15vh]">
                <text className="text-[25px] font-extrabold w-full bg-white"> Welcome back, User </text>
                <div className="relative">
                    <img src={ search } className="absolute top-2 left-1" />
                    <input type="text" className="w-[30%] h-[40px] pl-10 rounded-[5px] border border-[#686868]" />
                </div>
                <div className="flex flex-row justify-between h-[100%] pt-[5vh]">
                    <div className="w-[50%] h-[75vh]">
                        <text className="text-[#003B63] italic text-[20px]"> What's New </text>
                        <div id="box-1" className="bg-[#E3DEEB] px-5 rounded h-full">
                            <p className="mt-[10px] ml-[20px] font-semi-bold text-[20px]"> 3-Bedroom Flat for <span className="text-[#003B63]"> Mr. Skriller Ajan </span> </p>
                            <hr className="bg-[#fff] w-[95%] h-[2px]"/>
                            <div className="flex flex-col justify-center items-center">
                                <span> Stage: <span className="text-[#1270B0] font-bold mt-4">50%</span> </span>
                                <span className="my-[15px]"> Status - <span className="text-green-600 font-bold"> Ongoing </span> </span>
                                <div className="flex flex-row justify-around w-[70%]">
                                    <span className="font-thin w-[180px] text-end"> { "Field Agent(s):" } </span>
                                    <span className="flex flex-col font-">
                                        <span> FirstName Surname </span>
                                    </span>
                                </div>
                                <div className="flex flex-row justify-around w-[70%]">
                                    <span className="font-thin w-[180px] text-end"> { "Contractor(s):" } </span>
                                    <span className="flex flex-col font-">
                                        <span> FirstName Surname </span>
                                    </span>
                                </div>
                                <div className="flex flex-row justify-around w-[70%] my-[20px]">
                                    <text className="font-thin w-[180px] text-end"> { "Field Agent(s):" } </text>
                                    <text> FirstName Surname </text>
                                </div>
                                <div className="flex flex-row justify-around w-[70%]">
                                    <text className="font-thin w-[180px] text-end"> { "Duration:" } </text>
                                    <text className="-mr-[42px]"> 2023/08/10 - 2025/08/10 </text>
                                </div>
                                <div className="flex flex-row justify-around w-[70%] my-[20px]">
                                    <text className="font-thin w-[180px] text-end"> { "Contract Document:" } </text>
                                    <button className="w-[30%] relative" onClick={() => setVisible(true)}>
                                        <img className="w-full" src={doc} />
                                        <text className="text-[12px] text-center absolute left-0 right-0 bottom-0"> Click to view </text>
                                        <img src={clickview} />
                                    </button>
                                    <div className="card flex justify-content-center">
                                    <Dialog header="Header" visible={visible} maximizable style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                                        <p className="m-0">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                                            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                    </Dialog>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FieldAgentHome;