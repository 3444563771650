import React from "react";
import Search from "./components/search";
import Table from './components/contractTable'



const Contracts = () =>{
    return (
        <div>
            
            <div className="flex flex-row justify-between pt-[15vh] items-center  h-[50px] px-[30px] mb-[20px] pt-[110px] pb-[30px]">
                <p className="font-bold text-[26px]">Contracts</p>
                <button className="bg-black text-[#CEB0FF] py-[8px] px-[8px] rounded-md">
                    &#43; Create Contract Request
                </button>
            </div>
            <Search/>
            <Table/>
            


        </div>
    );
}

export default Contracts