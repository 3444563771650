import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import LogoImg from "./assets/Logo.png";
import { Checkbox } from 'primereact/checkbox';
import { SingUpGoogle, Submitbtn } from './components/buttons.jsx';
import InputTextField from './components/inputTextField.jsx';
import RememberMe from './components/rememberMe.jsx';
import PasswordField from './components/passwordField.jsx';
import { loginDetails } from './signup-logins.js';
import { SignInContext } from '../Contexts/SignInContext.jsx';

export default function Signin() {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  })
  const navigate = useNavigate();
  const [canLogin, setCanLogin] = useState(true);
  const { userRole, setUserRole } = useContext(SignInContext);

  const handleEmailChange = (event) => {
    setFormData((prevState) => ({ ...prevState, email: event.target.value }));
  };

  const handlePasswordChange = (event) => {
    setFormData((prevState) => ({ ...prevState, password: event.target.value }));
  };

  const handleRoleChange = (role) => {
    setUserRole(role);
  };

  const Login = (event) => {
    event.preventDefault();
    if (formData.email === loginDetails.email && formData.password === loginDetails.password && (userRole === 'client' || 'contractor' || 'field-agent' || 'compliance' || 'administrator' )) {
      if(userRole === 'contractor') {
        navigate('/contractor/Home');
      } else if(userRole === 'client') {
        navigate('/client/home');
      } else if(userRole === 'field-agent') {
        navigate('/field_agent/home');
      } else if(userRole === '/compliance') {
        navigate('/compliance/home');
      } else if(userRole === 'administrator') {
        navigate('/administator/home');
      }
    } else {
      setCanLogin(false);    
    }
  };

  return (
    <form onSubmit={Login} className='flex flex-col-reverse md:flex-row items-center'>
        <div className="flex justify-center md:absolute md:top-[110%] md:left-[10%] md:h-0 mt-[40px] md:w-[15%]">
        <div className="md:absolute md:-left-[40%] md:-top-[45vh] md:p-3 lg:relative">
            <img className="w-[100px] md:w-[190px]" src={ LogoImg } />
        </div>
        </div>
        <div className='flex flex-col md:flex-col justify-center mx-auto items-center gap-8 pb-8'>
          <div className='singin text-[32px] mt-[10vh]'>Sign In</div>
          <SingUpGoogle properties={{label:"Sign in with"}}/>
          <div className='w-[100%] flex items-center gap-6'>
            <hr className='bg-[#412d7c] h-[2px] w-[50%]'/>
            <span className='text-xs'>or</span>
            <hr className='bg-[#412d7c] h-[2px] w-[50%]'/>
          </div>
          <div>Sign in using your email and password</div>
          <div className='grid grid-cols-3 gap-y-2 w-[100%]'>
            <label className='checkItem'>
            <Checkbox
                id="contractor"
                inputId="contractor"
                value="contractor"
                checked={userRole === 'contractor'}
                onChange={() => handleRoleChange('contractor')}
            />
              <span className='ml-1'>Constructor</span>
            </label>
            <label className='checkItem'>
            <Checkbox
                id="client"
                inputId="client"
                value="client"
                checked={userRole === 'client'}
                onChange={() => handleRoleChange('client')}
            />
              <span className='ml-1'>Client</span>
            </label>
            <label className='checkItem'>
            <Checkbox
                id="field-agent"
                inputId="field-agent"
                value="field-agent"
                checked={userRole === 'field-agent'}
                onChange={() => handleRoleChange('field-agent')}
            />
              <span className='ml-1'>Field Agent</span> 
            </label>
            <label className='checkItem'>
            <Checkbox
                id="compliance"
                inputId="compliance"
                value="compliance"
                checked={userRole === 'compliance'}
                disabled={true}
                onChange={() => handleRoleChange('compliance')}
            />
              <span className='ml-1'>Compliant</span>
            </label>
            <label className='checkItem'>
            <Checkbox
                id="administrator"
                inputId="administrator"
                value="administrator"
                disabled={true}
                checked={userRole === 'administrator'}
                onChange={() => handleRoleChange('administrator')}
            />
              <span className='ml-1'>Administrator</span>
            </label>
          </div>
          <InputTextField label="Email" value={formData.email} setValueFunc={handleEmailChange}/>
          <PasswordField label="Password" feedback={false} value={formData.password} setPasswordFunc={handlePasswordChange}/>
          {
            canLogin ? <span> </span> : <div className='text-red-700 text-[13px] text-left -mt-[3vh]'>Please enter the right Email and Password <div> and ensure that you have checked a category</div></div>
          }
          <RememberMe/>
          <Submitbtn  properties={{ label: 'Sign In' }}/>
        </div>
    </form>
  )
}