import React, { useState } from "react";


const ScheduleMeeting = () => {
    const [meetingTimes, setMeetingTimes] = useState('')
    const handleCountryChange = (e) => {
        setMeetingTimes(e.target.value);
    };

    const meetingTime = [
        "7:30:00", "12:30:00", "18:30:00"

    ]

    return (
        <div className="pt-[14vh] px-[30px] overflow-x-hidden">
            <p className='text-[26px] font-bold'> Schedule Meeting </p>
            <div className="flex flex-row justify-between px-[20px] h-[90vh]">
                <div className="h-[95%] bg-[#46257A26] w-[59%] px-10 relative">
                    <form className="w-[70%]">
                        <div className="flex flex-col pb-[25px] pt-[50px]">
                            <label className="text-[13px]"> Agenda* </label>
                            <input className="outline-none h-[40px] w-[40vw] rounded-md" type="text"/>
                        </div>
                        <div className="flex flex-col pb-[25px]">
                            <label className="text-[13px]"> Meeting platform* </label>
                            <input className="outline-none h-[40px] w-[40vw] rounded-md" type="text"/>
                        </div>
                        <div className="flex flex-col pb-[25px]">
                            <label className="text-[13px]"> Meeting link* </label>
                            <input className="outline-none h-[40px] w-[40vw] rounded-md" type="text"/>
                        </div>
                        <div>
                            <text className="font-bold"> Attendees </text>
                            <div className="flex flex-row justify-between py-[8px]">
                                <label>
                                    <input className="border border-black mr-1" type="checkbox" />
                                    Contractor
                                </label>
                                <label>
                                    <input className="border border-black mr-1" type="checkbox" />
                                    Client
                                </label>
                                <label>
                                    <input className="border border-black mr-1" type="checkbox" />
                                    Witness
                                </label>
                                <label>
                                    <input className="border border-black mr-1" type="checkbox" />
                                    Agent
                                </label>
                            </div>
                        </div>
                        <div className='w-[13vw] h-[60px] flex flex-row justify-between items-center absolute right-2 bottom-0'>
                            <button className='bg-purple-200 px-[24px] py-[10px] rounded-md'> Back </button>
                            <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> + Add </button>
                        </div>
                    </form>
                </div>
                <div className="h-[95%] bg-[#46257A26] w-[40%] relative">
                    <div className="p-5">
                        <div className="w-[70%] h-[15vh] bg-[#fff] flex flex-col px-[85px] rounded leading-7">
                            <text className="font-bold text-[18px]"> Last Meeting Schedule </text>
                            <span className="pl-4"> Date: July 22, 2023 </span>
                            <span className="pl-4"> Time: 18:00:00 </span>
                        </div>
                        <div className="py-[30px] flex flex-col">
                            <text className="font-bold"> Select meeting time </text>
                            <span>
                                <select onChange={handleCountryChange} className='outline-none h-[40px] w-[380px] rounded-md bg-[#fff]'>
                                    <option label="18:30:00"></option>
                                    {meetingTime.map((time, index) => (
                                        <option key={index} value={time}>
                                            {time}
                                        </option>
                                    ))}
                                </select>
                            </span>
                        </div>
                        <div>
                            <text className="font-bold"> Select date </text>
                        </div>
                        <div className='w-[13vw] h-[60px] flex flex-row justify-between items-center absolute -right-20 bottom-0'>
                            <button className='bg-black text-[#FFE3B9]  px-[24px] py-[10px] rounded-md'> + Add </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleMeeting;