import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../BeginningPages/assets/Logo.png";
import bellicon from "../assets/bellicon.svg";
import usericon from "../assets/usericon.png";
import menu from "../assets/menu.png";
import home from "../assets/home.png";
import profile from "../assets/profile.png";
import contract from "../assets/contract.png";
import feedback from "../assets/feedback.png";
import complaints from "../assets/complaint.png";
import remainder from "../assets/remainder.png";
import dropdown from "../assets/dropdown.png";
import signout from "../assets/signout.png";
import { contractOptions, profileOption } from "../data";

import 'aos/dist/aos.css';

export const SignedInNavbar = () => {
    return (
        <nav className="drawer flex flex-col z-50 w-full top-0 fixed">
            <input id="my-drawer" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content">
            <div id="container_nav">
            <div id='nav_1' className="flex flex-row justify-between items-center w-full h-[40px] md:px-[30px] bg-white">
                    <img className="w-[40px] h-[40px] my-auto rounded cursor-pointer" src={Logo}/>
                    <div className="text-[15px] ml-12"> <span className="font-bold"> RADICI </span> CONSULT LTD  </div>
                    <div className="flex flex-row justify-between mr-10 w-[12%]">
                        <img className="w-[20px]" src={ bellicon } />
                        <span className="flex flex-row items-center">
                            <text className="ml-7 text-[15px]">Username</text>
                            <img className="w-[20%] ml-4" src={ usericon } />
                        </span>
                    </div>
                </div>
                <div id='nav_2' className="flex flex-row items-center w-full h-[59px] mb-0 -mt-2">
                    <label htmlFor="my-drawer" className="drawer-button flex flex-row justify-between cursor-pointer bg-black items-center w-[7%] h-[43px] px-3">
                        <img className="w-[25px] h-[20px]" src={ menu } />
                        <text className="font-bold text-[15px] text-white"> MENU </text>
                    </label>
                    <div className="bg-[#46257a] text-[#46257a] border-l-[3px] border-white w-[93%] h-[43px]"> h </div>
                </div>
            </div>
            </div> 
            <div className="drawer-side p-0">
                <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
                <div className="menu w-[15%] min-h-full bg-base-200 text-base-content p-0">
                    <SideBar />
                </div>
            </div>
        </nav>
    )
}


const SideBar = () => {
    const [showContractOptions, setShowContractOptions] = useState(false);
    const [showProfileOptions, setShowProfileOptions] = useState(false);
    const [rotateProfileDropdown, setRotateProfileDropdown] = useState(false);
    const [rotateContactDropdown, setRotateContactDropdown] = useState(false);

    const handleContractClick = () => {
        setShowContractOptions(!showContractOptions);
        setRotateContactDropdown(!rotateContactDropdown);
    };

    const handleProfileClick = () => {
        setShowProfileOptions(!showProfileOptions);
        setRotateProfileDropdown(!rotateProfileDropdown);
    };

    let profileRotation = '';
    let contactRotation = '';

    if(rotateProfileDropdown) {
        profileRotation = 'rotate-0';
    } else {
        profileRotation = '-rotate-90';
    }

    if(rotateContactDropdown) {
        contactRotation = 'rotate-0';
    } else {
        contactRotation = '-rotate-90';
    }

    const navigate = useNavigate();

    return (
        <div id='side_bar' className="flex flex-col p-0 justify-between items-center bg-black h-full w-full" >
            <div className="w-full h-[72vh] flex flex-col gap-y-3 py-8">
            <Link to='/contractor/Home' className="flex flex-row px-3 items-center w-[90%] h-[40px] rounded bg-[#CEB0FF]"> <img className="w-[20px] mr-2" src={ home } /> Home </Link>
            <button className="flex flex-row px-3 items-center w-[90%] h-[40px] text-white font-bold"
            onClick={handleProfileClick}> <img className="w-[20px] mr-2" src={ profile } /> Profile <img className={`ml-5 w-[13px] ${profileRotation}`} src={dropdown} /></button>

            {showProfileOptions && (
                <div className="flex flex-col w-3/4 mx-auto">
                {profileOption.map((option, index) => (
                    <Link
                    key={index}
                    className="flex flex-row items-center text-[12px] w-full h-[30px] text-white font-bold mb-2"
                    to= {option.link}
                    >
                    {option.profileOption}
                    </Link>
                ))}
                </div>
            )}

            <button className="flex flex-row px-3 items-center w-[90%] h-[40px] text-white font-bold"
            onClick={handleContractClick}> <img className="w-[20px] mr-2" src={ contract } /> Contract <img className={`ml-5 w-[13px] ${contactRotation}`} src={dropdown} /> </button>

            {showContractOptions && (
                <div className="flex flex-col w-3/4 mx-auto">
                {contractOptions.map((options, index) => (
                    <Link
                    key={index}
                    to={options.link}
                    className="flex flex-row items-center text-[12px] w-full h-[40px] text-white font-bold"
                    >
                    {options.option}
                    </Link>
                ))}
                </div>
            )}

            <button className="flex flex-row px-3 items-center my-[10px] w-[90%] h-[40px] text-white font-bold"
            onClick={() => navigate('/contractor/feedback')}> 
                <img className="w-[20px] mr-2" src={ feedback } /> Feedback </button>
            <button className="flex flex-row px-3 items-center my-[10px] w-[90%] h-[40px] text-white font-bold"
            onClick={() => navigate('/contractor/complaint')}> 
                <img className="w-[20px] mr-2" src={ complaints } /> Complaints </button>
            <Link className="flex flex-row px-3 items-center my-[10px] w-[90%] h-[40px] text-white font-bold" to="/contractor/projectreminder"> <img className="w-[20px] mr-2" src={ remainder } /> Reminders </Link>
            </div>
            <div className="w-full">
                <hr className="bg-[#b488f9] w-full h-[2px] mt-[20vh] "/>
                <button className="flex flex-row mx-auto py-[20px]"
                onClick={() => navigate('/')}>
                    <img className="w-[20px]" src={ signout } />
                    <text className="text-[#CEB0FF] ml-[10px]"> Sign out </text>
                </button>
            </div>
        </div>
    )
}

