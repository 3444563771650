import React,{useState} from 'react';
import doc from '../assets/doc.svg'
import small from '../assets/sml.svg'
import big from '../assets/big.svg'


const ContractModal = ({onClose})=>{
    const handleOnClose = () =>{
        
        onClose()
    }
    return(
        <div>
            <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
                <div  className='bg-white p-[30px]  w-[680px] h-[560px] rounded overflow-hidden animate-modal'>
                    <p className="font-bold text-xl mt-[10px]">Contract 1234</p>
                    <div className='h-[420px] mt-[10px] rounded bg-[#46257A26]'>
                        <div className='flex flex-row p-[20px] justify-between border-b border-white ]'>
                            <p className='font-bold'> 3-bedroom flat</p>
                            <p className='font-bold'>ID 1234</p>

                        </div>
                        <div className='flex flex-row'>
                            <div className='w-[600px] border-r border-white flex justify-center pt-[10px] pl-[10px]'>
                                <div className='flex items-start flex-col space-y-5'>
                                    <p>Status: <span className='text-green-500'>Accepted</span></p>
                                    <p> Awarded to: <span>Contractor 123</span></p>
                                    <p>Bid Duration: <span> 6 months</span></p>
                                    <p>Bid Price: <span>50000</span></p>
                                    <p>Contractor's Name: <span>Kwadwo</span></p>
                                    <div className='flex flex-row space-x-5'>
                                        <p>Document: </p>
                                        <img src={doc} className='w-[120px]'/>
                                    </div>
                                    <p>Progress Reports: <span className='text-blue-500'>erica@gmail.com</span></p>
                                
                            </div>
                            </div>

                            <div>
                        <div className='flex flex-col justify-between  w-[100px] space-y-[20px] pl-[10px] pt-[30px] '>
                            <div className='flex flex-row space-x-10 justify-between'>
                                <img src={small} className='w-[70px]'/>
                                <img src={small}  className='w-[70px]'/>
                                <img src={small}  className='w-[70px]'/>

                            </div>
                            <div className='flex flex-row space-x-10 justify-between '>
                                <img src={small}  className='w-[70px]'/>
                                <img src={small}  className='w-[70px]'/>
                                <img src={small}  className='w-[70px]'/>


                            </div>
                            
                        </div>
                        <div className='mt-[20px] flex justify-center items-center'>
                            <img src={big} className='w-[600px] h-[120px] object-cover pl-[10px] pr-[10px]'/>

                        </div>

                    </div>
                        </div>

                    </div>
                    <div className=' md:px-[30px]  py-[5px] h-[60px] flex flex-row w-full md:w-full justify-end items-center space-x-8'>
                        <button className='bg-purple-200 px-[24px] py-[10px] rounded-md' onClick={onClose}> Back </button>
                        <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> Export </button>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default ContractModal