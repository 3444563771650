import React,{useState, useEffect} from 'react';
import pana from './assets/pana.png';
import second from './assets/second.png';
import cons from './assets/cons.png';
import book from './assets/book.png';
import commit from './assets/commit.png';
import trust from './assets/trust.png';
import account from './assets/account.png';
import Navbar from './components/Navbar';
import LogoImg from '../BeginningPages/assets/Logo.png';
import footerImg from "../BeginningPages/assets/footerImg.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';


const AboutUs = ()=> {
    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <div className='overflow-hidden'>
            <div className='w-[110px] mx-auto mb-8'>
                <p className="text-[24px] text-black text-opacity-72 font-bold  z-10  mx-auto mt-28">
                    About Us
                </p>
                <hr data-aos-duration="700" data-aos="fade-right" className='h-[5px] w-full rounded-[10px] bg-gradient-to-r from-black to-indigo-700'/>
            </div>
            <div className="flex flex-col md:flex-row items-center mx-auto md:justify-center md:space-x-10 mt-5 md:mt-10">
                <div className="w-full space-y-5 my-10 order-2 md:order-1 flex flex-col justify-center md:w-[500px]  md:h-[390px] md:space-y-10">
                    <p className="text-2xl md:text-4xl text-[#46257A] text-opacity-72 font-bold text-center">Radici</p>
                    <p className="text-sm text-center md:text-base">
                        Welcome to Radici – Building Dreams, Building Trust. At Radici, we understand the sacrifices made by hardworking individuals who seek a brighter future abroad, aiming to provide comfort and security for their families back home. Our mission is to empower you to realize your dreams without the fear of your hard-earned money going to waste. Radici is your trusted bridge to turn aspirations into reality.
                    </p>
                    <div className="my-4 md:my-6">
                        <a href="#" className="text-white hover:text-gray-400 transition duration-300">
                            <div className="bg-[#46257A] text-white rounded-md py-2 px-4 md:px-6 w-[140px] mx-auto">
                                Contact Us
                            </div>
                        </a>
                    </div>
                </div>

                <div className="order-1 md:order-2 object-cover">
                    <img data-aos-duration="700" data-aos="zoom-out" src={pana} alt="pana" className="w-full h-auto mx-auto md:w-[616px] md:h-[390px]" />
                </div>
            </div>




            <div className="py-12 md:py-0 flex flex-col md:flex-row md:justify-center md:items-center items-center mx-auto justify-center space-y-4 md:space-x-0 mt-5 md:mt-10 mb-5 md:mb-10 bg-[#46257A26]">

                <img data-aos-duration="700" data-aos="fade-right" src={second} alt="pana" className="w-full max-h-[300px] md:max-h-[500px] mx-auto md:top-0 md:left-0  md:w-[500px] " />

                <div className="w-full space-y-5 flex flex-col justify-center md:h-[390px]  md:w-[800px] md:pr-[180px] md:justify-center md:items-center">
                    <p className="text-2xl md:text-3xl text-[#46257A] text-opacity-72 sm:mt-5 md:mt-0 font-bold text-center">Who are we?</p>
                    <p className='text-center'>
                        Radici is more than a platform; we are a community of dreamers, builders, and change-makers. We are individuals who have felt the yearning for brighter futures and the determination to make them happen. We are the architects of trust, the guardians of dreams, and the bridge that connects hearts across borders.
                    </p>
                </div>

            </div>


            <div className="flex flex-col items-center mx-auto justify-center  md:space-x-20 md:flex-row mt-3 md:mt-10 px-4 space-y-10">
                <div className="w-full space-y-5 md:w-[500px]  md:h-[390px] md:space-y-10 md:md:w-[500px] md:justify-center md:items:center md:flex md:flex-col">
                    <div className="text-left border-l-4 pl-4 space-y-2 md:space-y-5">
                        <p className="text-2xl text-[#46257A] text-opacity-72 font-bold">Our Vision</p>
                        <p>Contractor & Client Company</p>
                    </div>
                    <p className="text-center">
                        Welcome to Radici – Building Dreams, Building Trust. At Radici, we understand the sacrifices made by hardworking individuals who seek a brighter future abroad, aiming to provide comfort and security for their families back home. Our mission is to empower you to realize your dreams without the fear of your hard-earned money going to waste. Radici is your trusted bridge to turn aspirations into reality.
                    </p>
                </div>
                <img data-aos-duration="700" data-aos="fade-left" src={cons} alt="cons" className="w-full rounded-lg md:w-[470px] " />
            </div>




            <div className=''>
                <p className='mx-auto font-bold text-center mt-10 text-2xl mb-10'>Our Story</p>
                <div>
                    <p className='md:px-[100px] text-center bg-[#46257A26] mx-auto h-[320px] md:h-[260px] flex items-center justify-center   md:leading-loose '>
                        The story of Radici began with a deep understanding of the heartache and frustration experienced by individuals who work tirelessly to support their loved ones back home, only to discover that their hard-earned money has not been used for its intended purpose. Our founders have walked in your shoes, and this personal experience ignited the spark for Radici. We believe that everyone deserves to see their dreams grow and flourish, irrespective of geographical boundaries
                    </p>
                </div>
            </div>




            <div className='py-4 mx-auto max-w-screen-md  w-[300px]'>
                <p className="text-2xl text-black text-opacity-72 font-bold text-center mt-10 mb-0">Our Company's Values</p>
                <hr data-aos-duration="700" data-aos="fade-left" className='h-[5px] w-[60%] mx-auto rounded-[10px] bg-gradient-to-r from-black to-indigo-700'/>
            </div>
            <div className='flex flex-col md:flex-row justify-center md:items-baseline md:space-x-20  px-4 md:px-8 mb-6 mt-6'>
                <div className='mb-6 flex flex-col items-center space-y-5 text-center w-full md:w-1/2 max-w-md'>
                    <img data-aos-duration="700" data-aos="fade-up" src={book} alt='book' className='w-24 h-18' />
                    <p className='text-2xl text-black text-opacity-72 font-bold'>Transparency</p>
                    <p className='leading-loose'>Transparency is non-negotiable. We believe in open and honest communication. Every transaction, every step of the project, and every penny spent will be transparent and easily accessible to you.</p>
                </div>
                <div className='mb-6 flex flex-col items-center text-center space-y-5 w-full md:w-1/2 max-w-md'>
                    <img data-aos-duration="700" data-aos="fade-up" src={commit} alt='commitment' />
                    <p className='text-2xl text-black text-opacity-72 font-bold'>Commitment</p>
                    <p className='leading-loose'>At Radici, commitment is a cornerstone of our values. We are unwavering in our dedication to realizing your dreams. We stand by your side, ensuring your aspirations are not just promises but tangible, meaningful realities.</p>
                </div>
            </div>

            <div className='flex flex-col md:flex-row justify-center px-4 md:px-8 mt-6 md:items-baseline md:space-x-20 mb-[0px]'>
                <div className='mb-6 flex flex-col items-center text-center w-full md:w-1/2 space-y-5 max-w-md'>
                    <img data-aos-duration="700" data-aos="fade-up" src={trust} alt='trust' className='w-24 h-18' />
                    <p className='text-2xl text-black text-opacity-72 font-bold'>Trust</p>
                    <p className='leading-loose'>Trust is at the core of Radici. We are committed to fostering trust between you and the professionals you entrust with your dreams. We ensure transparency and accountability in every step of the process.</p>
                </div>
                <div className='mb-6 flex flex-col items-center text-center w-full space-y-5 md:w-1/2 max-w-md'>
                    <img data-aos-duration="700" data-aos="fade-up" src={account} alt='accountability' />
                    <p className='text-2xl text-black text-opacity-72 font-bold'>Accountability</p>
                    <p className='leading-loose'>We hold ourselves and our partners accountable to deliver on our promises. We carefully vet and work with qualified professionals who share our commitment to integrity.</p>
                </div>
            </div>
            <footer className='hidden'>
                <div className="flex flex-row justify-between relative">
                    <div className="flex flex-col justify-center items-start md:p-3 absolute top-[10vh] left-[3vw] lg:relative">
                        <img className="w-[100px] md:w-[190px]" src={ LogoImg } />
                    </div>
                    <img className="md:h-[20vh] -z-[50] absolute left-[28vw] md:left-[30vw] top-[34vh] md:top-[25vh]" src={ footerImg }/>
                </div>
            </footer>
            <footer className='flex flex-col items-center pt-[10vh]'>
                <img className="w-[100px] mb-[5vh]" src={ LogoImg } />
                <img className="" src={ footerImg }/>
            </footer>

        </div>
    )
}

export default AboutUs;
