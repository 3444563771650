import React, { useState } from 'react';
import profilePic from "./assets/profilepic.svg";
import doc from "./assets/doc.svg";
import file from "./assets/file.svg";

const ContractorProfile = () => {
    const data = [
        { clientId: '001', bidRequest: '12345', clientName: "FirstName Surname", agentName: 'FirstName Surname', status: 'ongoing', timestamps: '123456789' },
        { clientId: '002', bidRequest: '12345', clientName: "FirstName Surname", agentName: 'FirstName Surname', status: 'ongoing', timestamps: '987654321' },
        { clientId: '003', bidRequest: '12345', clientName: "FirstName Surname", agentName: 'FirstName Surname', status: 'ongoing', timestamps: '123456789' },
        { clientId: '004', bidRequest: '12345', clientName: "FirstName Surname", agentName: 'FirstName Surname', status: 'ongoing', timestamps: '987654321' },
        { clientId: '005', bidRequest: '12345', clientName: "FirstName Surname", agentName: 'FirstName Surname', status: 'ongoing', timestamps: '987654321' }
    ];

    return (
        <div>
            <div className='flex flex-col px-[30px] pt-[14vh]'>
                <text className='text-[26px] font-bold'> My Profile </text>
            <div className='border'>
                <div className='flex flex-row justify-between h-[32vh]'>
                    <span className='border-r-2 w-[23%]'>
                        <text> Stephen Appiah </text>
                        <span className='flex flex-row'>
                            <img src={ profilePic } alt='' className='w-[13vw]'/>
                            <span className='flex flex-col'>
                                <span className='text-[10px] text-center'><img src={ doc } alt="" className='w-[7vw]'/> <span className=''>license.docx</span></span>
                                <span className='text-[10px] text-center'><img src={ doc } alt="" className='w-[7vw]'/> collateral.docx</span>
                            </span>
                        </span>
                    </span>
                    <span className='flex flex-col justify-between px-6 py-7 border w-[38%]'>
                        <text>Email: <a>texst@gmail.com</a></text>
                        <text>Contact: 1234567890</text>
                        <text>Country: Ghana</text>
                        <text>Address: Tilak Ave, Accra</text>
                    </span>
                    <span className='flex flex-col justify-between px-6 py-7 border w-[38%]'>
                        <text>Occupation: Mason</text>
                        <text>Website URL: <a>dummymail.com</a></text>
                        <text>Next of Kin: Shatta</text>
                        <text>NoK Contact: 0987654321</text>
                    </span>
                </div>
                <div>
                    <text className='text-[#003B63] p-5 font-bold text-[20px]'> Contracts: </text>
                    <table className="w-full bg-white border border-gray-300 mt-[10px] mb-[10vh]">
                        <thead className='bg-[#c0b4cc]'>
                            <tr>
                                <th className="py-2 px-4 border-b">Client ID</th>
                                <th className="py-2 px-4 border-b">Bid Request</th>
                                <th className="py-2 px-4 border-b">Client Name</th>
                                <th className="py-2 px-4 border-b">Agent's Name</th>
                                <th className="py-2 px-4 border-b">Status</th>
                                <th className="py-2 px-4 border-b">Timestamps</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => 
                            (
                            <tr key={index} className={index % 2 === 0 ? 'bg-[#fff]' : 'bg-[#e0d4e4]'}>
                                <td className="py-2 px-4 text-center border-b">{item.clientId}</td>
                                <td className="flex justify-center py-2 border-b"><img src={file} /></td>
                                <td className="py-2 px-4 text-center border-b">{item.clientName}</td>
                                <td className="py-2 px-4 text-center border-b">{item.agentName}</td>
                                <td className="py-2 px-4 text-center border-b">{item.status}</td>
                                <td className="py-2 px-4 text-center border-b">{item.timestamps}</td>

                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            <div className=' px-[30px] h-[60px] flex flex-row fixed bottom-0 right-0 justify-end items-center space-x-8'>
                <button className='bg-purple-200 px-[24px] py-[10px] rounded-md'> Back </button>
                <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> Edit </button>
            </div>

        </div>

    );
};

export default ContractorProfile;
