import COUNTRIES from "./countries";
import ReactCountryFlag from "react-country-flag";;


const SelectCountry = ({ handleCountryChange }) => {
    // const [country, setCountry] = useState('');
    
    // const handleCountryChange = (e) => {
    //     setCountry(e.target.value);
    // };

    return (
        <div className='flex flex-col'>
            <label className='text-xs'>Country of Residence</label>
            <select onChange={handleCountryChange} className='outline-none h-[40px] rounded-md w-full'>
                <option value=""></option>
                {COUNTRIES.map((country, index) => (
                    <option key={index} value={country}>
                        <span> <ReactCountryFlag
                            className="emojiFlag"
                            countryCode={country.value}
                            style={{
                                fontSize: '2em',
                                lineHeight: '2em',
                            }}
                            aria-label="United States"
                        /> </span>
                        {country.title}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default SelectCountry;