import React, { useState } from 'react';
import SelectCountry from './components/SelectCountry';
import { useNavigate } from 'react-router-dom';


const countries = [
    'Country 1',
    'Country 2',
    'Country 3',
    // Add more countries as needed
];

const EditProfileFirst = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('');
    const [address, setAddress] = useState('');
    const [profilePicture, setProfilePicture] = useState(null);
    const [occupation, setOccupation] = useState('');
    const [pointOfContact, setPointOfContact] = useState('');
    const [contact, setContact] = useState('');
    const [id, setId] = useState('');
    const [documents, setDocuments] = useState(null);


    const navigate = useNavigate();

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform form submission logic here
    };


    return (
        <div>
            <div className='flex flex-col px-[30px] pt-[14vh]'>
                <div className=' h-[80px] flex flex-col justify-between'>
                    <p className='text-[26px] font-bold'>Edit profile</p>
                </div>

                <div className='flex flex-row justify-around items-center bg-[#46257A26] p-[60px]'>
                    <div className='flex flex-col space-y-30'>
                        <form onSubmit={handleSubmit} className='flex flex-col justify-between items-start h-[250px]'>
                            <div className='flex flex-col'>
                                <label className='text-[#00000099] text-[14px]' >Phone Number*</label>
                                <div>
                                    <div >
                                        <input
                                            className='outline-none h-[40px] w-[380px] rounded-md'
                                            type="tel"
                                             
                                            // onChange={handlePhoneNumberChange}
                                        />


                                    </div>

                                </div>

                            </div>
                            <div className='flex flex-col w-full'>
                                <label className='text-[#00000099] text-[14px]'>Country of Residence*</label>
                                <SelectCountry />
                            </div>
                            <div className='flex flex-col'>
                                <label className='text-[#00000099] text-[14px]' >Address*</label>
                                <div>
                                    <div >
                                        <input
                                            className='outline-none h-[40px] w-[380px] rounded-md'
                                            type="text"
                                            // onChange={handleAddressChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* form 2 */}
                    <div className='flex flex-col'>
                        <form onSubmit={handleSubmit} className='flex flex-col justify-between items-start  h-[250px]'>
                            <div className='flex flex-col'>
                                <label className='text-[#00000099] text-[14px]' > Website URL* </label>
                                <div>
                                    <div >
                                        <input
                                            className='outline-none h-[40px] w-[380px] rounded-md'
                                            type="text"
                                            // onChange={handlePhoneNumberChange}
                                        />


                                    </div>

                                </div>

                            </div>
                            <div className='flex flex-col'>
                                <label className='text-[#00000099] text-[14px]' >Point Of Contact*</label>
                                <div>
                                    <div >
                                        <input
                                            className='outline-none h-[40px] w-[380px] rounded-md'
                                            type="text"
                                            // onChange={handleContactChange}
                                        />


                                    </div>

                                </div>

                            </div>
                            <div className='flex flex-col'>
                                <label className='text-[#00000099] text-[14px]' >Phone number of point contact*</label>
                                <div>
                                    <div >
                                        <input
                                            className='outline-none h-[40px] w-[380px] rounded-md'
                                            type="text"
                                            // onChange={handleAddressChange}
                                        />


                                    </div>

                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className=' px-[30px] h-[60px] flex flex-row fixed bottom-0 right-0 justify-end items-center space-x-8'>
                <button className='bg-purple-200 px-[24px] py-[10px] rounded-md'> Cancel </button>
                <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'
                onClick={()=>{navigate("/contractor/editprofile-phase_2")}}
                > Next </button>
            </div>

        </div>
        
        

    );
};

export default EditProfileFirst;
