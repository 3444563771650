import React from 'react'
import { Checkbox } from 'primereact/checkbox'
import { useState } from 'react'
export default function RememberMe() {
    const [checked, setChecked] = useState(false)
    return (
      <div className='flex justify-between items-center w-[100%]'>
        <div className='checkItem'>
        <Checkbox  onChange={(e) => setChecked(e.checked)} checked={checked} />
        <label htmlFor="remember" className="ml-2 text-xs">Remember me</label>
        </div>
      
      <a className='text-[#1270B0] text-xs'href="/signin/resetPassword">ForgotPassword</a>
  </div>
    )
}
