import React, {useState} from "react";
import { Datepicker } from 'flowbite-react';
import browse from './assets/browse.jpg';

const UpdateAgentProgressReport = () => {
    const [date, setDate] = useState(new Date());
    const [Media, setMedia] = useState(null);

    const handleMediaChange = (e) => {
        const file = e.target.files[0];
        setMedia(file);
    };
    return (
        <div>
            <div className='flex flex-col px-[30px] pt-[14vh]'>
                <div className=' h-[90%] flex flex-col justify-between pb-[30px]'>
                    <text className='text-[26px] font-bold mb-5'> Upload Progress Report </text>
                    <div className="bg-[#46257A26] h-[85vh]">
                        <div className="bg-[#BCB0CF] font-bold flex flex-row px-4 h-12 items-center">
                            <span> Constract ID - <text className="text-[#1270B0]"> 123456 </text> </span>
                            <span className=" ml-[30vw]"> Status: <text className="text-[#0F9103]"> Ongoing </text> </span>
                        </div>
                        <div className="pl-[40px] pr-[100px]">
                            <form className="flex flex-row justify-between">
                                <div className="flex flex-col w-[35%] mt-[30px]">
                                    <label className="flex flex-col">
                                        Visit Date*
                                        <div class="relative">
                                            <Datepicker minDate={new Date(2023, 0, 1)} maxDate={new Date(2023, 3, 30)} />
                                        </div>
                                    </label>
                                    <label className="flex flex-col mt-5">
                                        Vist Time*
                                    <input type="time" className="round"/>
                                    </label>
                                    <label className="flex flex-col mt-5">
                                        Timestamp*
                                        <div>
                                        <Datepicker minDate={new Date(2023, 0, 1)} maxDate={new Date(2023, 3, 30)} />
                                        </div>
                                    </label>
                                    <label className="flex flex-col mt-5">
                                        Percentage Complete*
                                        <div className="flex items-center relative">
                                            <text className="font-bold absolute left-[28vw]"> % </text>
                                            <input type="number" className="w-full h-[40px] pl-10 rounded-[5px] border-[#686868]" />
                                        </div>
                                    </label>
                                    <label className="flex flex-col mt-5">
                                        Issues/Concern*
                                        <textarea
                                            name="issues"
                                            placeholder="Write your concerns here"
                                            rows={4}
                                            cols={40}
                                        />
                                    </label>
                                </div>
                                <div className="mt-[30px]">
                                    <text> Upload Media* </text>
                                    <div className="h-[65vh] w-[30vw] bg-[#fff] relative">
                                        <div className='absolute bottom-0 right-0'>
                                            <label htmlFor="fileInput" className="cursor-pointer flex bg-white  md:w-[380px] rounded-md p-2 ">
                                                {Media ? (
                                                    <div className='flex justify-end  items-center md:w-[380px]'>
                                                        <img
                                                            src={URL.createObjectURL(Media)}
                                                            alt="Selected file"
                                                            className="w-40 h-40 object-cover rounded-md"
                                                        />
            
                                                    </div>
            
                                                ) : (
                                                    <div className=' flex justify-end  items-center w-[320px] md:w-[380px]'>
                                                        <img
                                                            src={browse}
                                                            alt="Choose File"
                                                            className="w-[110px]  object-cover"
                                                        />
            
                                                    </div>
            
                                                )}
                                            </label>
                                            <input
                                                id="fileInput"
                                                className="hidden"
                                                type="file"
                                                accept=".jpg, .png, .jpeg, .svg, .doc, .pdf"
                                                onChange={handleMediaChange}
                                            />
            
                                        </div>
                                    </div>
                                </div>
                                <div className=' px-[30px] h-[60px] w-full flex flex-row fixed bottom-0 right-0 justify-end bg-[#fff] items-center space-x-8'>
                                    <button className='bg-purple-200 px-[24px] py-[10px] rounded-md'> Cancel </button>
                                    <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> Save </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateAgentProgressReport;