
export const contractOptions = [
    { option: "Contracts", link: "/field_agent/contracts"},
    { option: "Progress Reports", link: "/field_agent/update_progress_report"},
];

export const profileOption = [
    {
        profileOption: "Profile",
        link: '/field_agent/profile'
    },
    {
        profileOption: "Edit profile",
        link: '/field_agent/edit_profile'
    }
];
