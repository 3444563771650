import React, { useState, useEffect, useContext } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { RoleSelectionContext } from '../../Contexts/SelectedRoleContext';

export default function ChooseRole() {
  const { signupRole } = useContext(RoleSelectionContext)
  const [isConstructor, setIsConstructor] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Set the checkbox state based on the selected role prop or URL parameter
    if (signupRole === 'contractor') {
      setIsConstructor(true);
      setIsClient(false);
    } else if (signupRole === 'client') {
      setIsConstructor(false);
      setIsClient(true);
    }
  }, [signupRole]);

  const handleConstructorChange = (e) => {
    setIsConstructor(e.checked);
    if (e.checked) {
      setIsClient(false);
    }
  };

  const handleClientChange = (e) => {
    setIsClient(e.checked);
    if (e.checked) {
      setIsConstructor(false);
    }
  };

  return (
    <div className='flex justify-between items-center w-[100%]'>
      <div className='checkItem'>
        <Checkbox onChange={handleConstructorChange} checked={isConstructor} />
        <label htmlFor="constructor" className="ml-2 text-xs">Constructor</label>
      </div>
      <div className='checkItem'>
        <Checkbox onChange={handleClientChange} checked={isClient} />
        <label htmlFor="Client" className="ml-2 text-xs">Client</label>
      </div>
    </div>
  );
}
