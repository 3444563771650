import react, {useState} from 'react';
import { Dropdown } from 'primereact/dropdown';
import ParentNav from './components/navigations';
import search from './assets/search.svg';
import file from './assets/file.svg';
import threedots from './assets/threebots.svg';


const ViewFieldAgentContracts = () => {
    const [sorted, setSorted] = useState('');
    const [selectedCity, setSelectedCity] = useState(null);

    const cities = [
        { name: "Client ID" },
        { name: "Location" },
        { name: "Start Date" },
        { name: "Duration" }
    ];

    const handleSortbyChange = (e) => {
        setSorted(e.target.value);
    };


    const data = [
        { contractID: '001', RequestID: '1235', coordinatorName: 'Ama Boateng', agentName: 'Kofi Boakye', Status: 'ongoing', timestamps: '123456789' },
        { contractID: '002', RequestID: '1236', coordinatorName: 'Kofi Boakye', agentName: 'Amina Sadik', Status: 'ongoing', timestamps: '987654321' },
        { contractID: '003', RequestID: '1237', coordinatorName: 'Amina Sadik', agentName: 'Adams Ramzy', Status: 'ongoing', timestamps: '123456789' },
        { contractID: '004', RequestID: '1238', coordinatorName: 'Adams Ramzy', agentName: 'Kofi Kingston', Status: 'ongoing', timestamps: '987654321' },
        { contractID: '005', RequestID: '1239', coordinatorName: 'Kofi Kingston', agentName: 'Ama Boateng', Status: 'ongoing', timestamps: '987654321' }
    ];

    return (
        <div>
            {/* <ParentNav /> */}
            <div className='pt-[14vh] px-[50px]'>
                <text className="text-[25px] font-extrabold w-full bg-white"> Contracts </text>
                <div className='flex flex-row w-full'>
                    <div className="relative w-[40vw]">
                        <img src={ search } className="absolute top-3 left-1" />
                        <input type="text" className="w-[80%] h-[7vh] pl-10 rounded-[5px] border border-[#686868]" />
                    </div>
                    <div className="card flex justify-content-center w-[20vw]">
                        <Dropdown value={sorted} onChange={(e) => setSorted(e.value)} options={cities} optionLabel="name" 
                            placeholder="Sort by:" className="w-full md:w-14rem" />
                    </div>
                </div>
            </div>
            <div className='px-[50px]'>
                <table className="w-full bg-white border border-gray-300 mt-[10px] mb-[10vh]">
                    <thead className='bg-[#c0b4cc]'>
                        <tr>
                            <th className="py-2 px-4 border-b">Contract ID</th>
                            <th className="py-2 px-4 border-b">Request ID</th>
                            <th className="py-2 px-4 border-b">Coordinator's Name</th>
                            <th className="py-2 px-4 border-b">Agent's Name</th>
                            <th className="py-2 px-4 border-b">Status</th>
                            <th className="py-2 px-4 border-b">Timestamps</th>
                            <th className="border-b"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => 
                        (
                        <tr key={index} className={index % 2 === 0 ? 'bg-[#fff]' : 'bg-[#e0d4e4]'}>
                            <td className="py-2 px-4 text-center border-b">{item.contractID}</td>
                            <td className="flex justify-center py-2 border-b">{item.RequestID}</td>
                            <td className="py-2 px-4 text-center border-b">{item.coordinatorName}</td>
                            <td className="py-2 px-4 text-center border-b">{item.agentName}</td>
                            <td className="py-2 px-4 text-center border-b">{item.Status}</td>
                            <td className="py-2 px-4 text-center border-b">{item.timestamps}</td>
                            <td className="py-2 w-7 text-center border-b"> <button> <img src={ threedots } /> </button> </td>

                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div id='footer' className=' px-[30px] h-[60px] flex flex-row w-full fixed bottom-0 justify-between items-center space-x-8 bg-black'>
                <button className='bg-[#CEB0FF] px-[24px] py-[10px] rounded-md'> Previous </button>
                <button className='bg-[#CEB0FF] px-[16px] py-[10px] rounded-md'> 1 </button>
                <button className='bg-[#CEB0FF] text-black  px-[24px] py-[10px] rounded-md'> Next </button>
            </div>
        </div>
    )
}


export default ViewFieldAgentContracts;