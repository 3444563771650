import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import Navbar from "./components/Navbar";
import landingImg1 from "./assets/landingImg1.svg";
import landingImg2 from "./assets/landingImg2.svg";
import landingImg3 from "./assets/landingImg3.svg";
import landingImg4 from "./assets/numbering.png";
import pencil from "./assets/pencil.svg";
import moneyHouse from "./assets/moneyHouse.svg";
import offices from "./assets/offices.svg";
import { useNavigate } from "react-router-dom";
import LogoImg from '../BeginningPages/assets/Logo.png';
import footerImg from "../BeginningPages/assets/footerImg.svg";
import one from "./assets/one.svg";
import two from "./assets/two.svg";
import three from "./assets/three.svg";
import four from "./assets/four.svg";
import arrow from "./assets/arrow.png";
import { RoleSelectionContext } from "../Contexts/SelectedRoleContext";

import AOS from 'aos';
import 'aos/dist/aos.css';


const LandingPage = () => {
    const navigate = useNavigate();
    const [fontSize, setFontSize] = useState(25);
    const { signupRole, setSignupRole } = useContext(RoleSelectionContext)

    

    const updateFontSize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 767) {
        setFontSize(25);
        } else if (windowWidth <= 1024) {
        setFontSize(17);
        } else {
        setFontSize(25);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateFontSize);
        updateFontSize();
        return () => {
        window.removeEventListener('resize', updateFontSize);
        };
    }, []);

    useEffect(() => {
        AOS.init();
      }, []);

    return(
        <div className="w-full overflow-hidden flex flex-col md:pb-[18vh]">
            <section className="pt-[80px] flex flex-col lg:w-[1500px]">
                <div id="1" className="flex flex-wrap md:flex-row justify-between mt-[0px] px-[20px] md:pl-[65px] pb-[4%] ">
                    <div className="flex flex-col items-center md:items-start w-full md:w-[700px] pt-[47px] ">
                        <img className="md:hidden absolute top-[30px] -z-10 w-full" src={ landingImg1 } />
                        <div className="flex flex-col md:flex-row text-[50px] md:text-[56px] font-extrabold justify-center items-center mt-[20vh] md:mt-[70px] md:justify-start w-full text-center md:text-start tracking-wider"> Welcome To <span className="text-[#412d7c] ml-2"> Radici </span> </div>
                        <div className="text-[27px] md:text-[34px] space-y-4 flex flex-col mt-[5px] md:mt-[33px] md:ml-[30px]"> 
                            <div className="-mb-5 text-center md:text-start"> Let's Build Your Dream </div> 
                            <div className="text-center md:text-start mt-3 md:mt-0"> Home Together! </div>
                        </div>
                        <div className="flex flex-col md:flex-row items-center md:justify-normal w-[80%] md:w-[90%] mt-[30px] md:mt-[100px]">
                            <Link to="/signuproleselected">
                                <button className="flex text-[#fff] bg-[#412d7c] justify-center items-center text-[100%] w-[60vw] md:w-[220px] mb-5 md:mb-0 md:h-[48px] h-[60px] rounded md:rounded-[10px] hover:bg-gray-800 md:mr-4 transition duration-500 ease-in-out"
                                onClick={()=> setSignupRole('client')}>
                                    <span className=""> I want to build a house </span>
                                </button>
                            </Link>
                            <Link to="/signuproleselected">
                                <button className="flex text-[#412d7c] bg-[#fff] justify-center items-center text-[100%] w-[60vw] h-[60px] md:w-[220px] md:h-[48px] border border-[#412d7c] rounded md:rounded-[10px] hover:bg-[#000] hover:text-[#fff] transition duration-500 ease-in-out"
                                onClick={()=> setSignupRole('contractor')}> 
                                    <span className=""> I am a Contractor </span>
                                </button>
                            </Link>
                        </div>
                    </div>
                    {/* <div data-aos-duration="700" data-aos="zoom-out" className="md:hidden flex justify-center"> 
                        <img className="w-[90%]" src={ landingImg1 } /> 
                    </div> */}
                    <div className="hidden md:block w-[700px]"> 
                        <img className="w-[86%]" src={ landingImg1 } /> 
                    </div>
                </div>
                <div id="2" className="flex flex-col md:flex-row justify-evenly md:justify-center md:items-center px-[20px] ">
                    <div className="hidden md:block mx-auto">
                        <img data-aos-duration="700" data-aos="fade-right" src={ landingImg2 } />
                    </div>
                    <div className="py-[30px] flex flex-col mx-auto md:mx-auto justify-center">
                        <div className="text-[40px] md:text-[36px] font-black tracking-widest leading-[40px] font-serif font-times">
                            <div> Track Progress </div>
                            <div> Seamlessly </div>
                        </div>
                        <div className="md:hidden my-[2vw]">
                            <img data-aos-duration="700" data-aos="fade-right" src={ landingImg2 } />
                        </div>
                        <div className="text-[25px] md:text-[18px] flex flex-col w-[350px] mt-[33px] md:mx-0 leading-[30px] font-serif font-times"> 
                            <div> "From Blueprint to
                             Reality - Stay in the
                             Know, Every Step of
                            the Way!" </div>
                        </div>
                        {/* <div className="my-[11px] italic text-[22px] w-[350px] md:mx-0 font-medium">
                            <a href="/signup" className="text-[#1270B0]"> Sign up to Radici </a>
                        </div> */}
                    </div>
                </div>
                <div id="3" className="flex flex-col md:flex-row md:items-center mx-auto md:mx-[120px] justify-center my-[80px]">
                    <div className="flex flex-col md:ml-[50px]">
                        <div className="text-[30px] md:text-[36px] mx-auto font-black leading-[42px] md:-mr-[115px] md:w-[25vw] font-serif font-times">
                            <div> A Walkthrough the </div>
                            <div> Home-Owning </div>
                            <div> Process </div>
                        </div>
                        <div className="flex flex-row justify-center items-center py-[30px]">
                            <div data-aos-duration="900" data-aos="fade-up" className="text-[18px] h-[600px]">
                                <div className="flex flex-row justify-around">
                                    <img src={one} />
                                    <div className="ml-[20px] w-[200px]"> Sign up and complete your profile. </div>
                                </div>
                                <img className="md:w-[1vw] h-[10vh] my-[15px] ml-3" src={arrow} />
                                <div className="flex flex-row justify-around top-[100px]">
                                    <img src={two} />
                                    <div className="ml-[20px] w-[200px]"> Describe your dream home or upload reference images. </div>
                                </div>
                                <img className="md:w-[1vw] h-[10vh] my-[15px] ml-3" src={arrow} />
                                <div className="flex flex-row justify-around top-[160px]">
                                    <img src={three} />
                                    <div className="ml-[20px] w-[200px]"> Review bids from contractors and choose your preferred one </div>
                                </div>
                                <img className="md:w-[1vw] h-[10vh] my-[15px] ml-3" src={arrow} />
                                <div className="flex flex-row justify-around top-[220px]">
                                    <img src={four} />
                                    <div className="ml-[20px] w-[200px]"> Begin the project once the contract is in place </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto mt-[40px] md:ml-[300px]">
                        <img className="rounded-[10px] w-[80vw] md:w-[200vh]" src={ landingImg3 } />
                    </div>
                </div>
                <div id="4" className="flex flex-col md:flex-row md:items-center mx-auto md:mx-[120px] justify-center mb-[10px] relative top-[0px] md:pr-[50px] ">
                    <div className="hidden md:block w-2/3 md:w-[480px] mx-auto md:ml-[50px] mt-[120px] ">
                        <img className="rounded-[10px]" src={ pencil } />
                    </div>
                    <div className="flex flex-col">
                        <div className="text-[30px] md:text-[36px] mx-auto font-black leading-[42px] font-serif font-times md:ml-[150px] my-5">
                            <div> A Walkthrough the </div>
                            <div> Contracting Process </div>
                        </div>
                        <div className="md:hidden w-[80%] mx-auto my-[30px] ">
                            <img className="rounded-[10px] md:ml-[10vw]" src={ pencil } />
                        </div>
                        <div className="flex flex-row justify-start py-[10px] px-[10vw]">
                            <div data-aos-duration="900" data-aos="fade-left" className="text-[18px] h-[600px]" >
                                <div className="flex flex-row">
                                    <img src={one} />
                                    <div className="ml-[20px] w-[200px]"> Register and complete your profile. </div>
                                </div>
                                <img className="md:w-[1vw] h-[10vh] my-[15px] ml-3" src={arrow} />
                                <div className="flex flex-row">
                                    <img src={two} />
                                    <div className="ml-[20px] w-[250px]"> Have your status activated to bid on available projects. </div>
                                </div>
                                <img className="md:w-[1vw] h-[10vh] my-[15px] ml-3" src={arrow} />
                                <div className="flex flex-row">
                                    <img src={three} />
                                    <div className="ml-[20px] w-[200px]"> Once your bid is accepted, a contract is created. </div>
                                </div>
                                <img className="md:w-[1vw] h-[10vh] my-[15px] ml-3" src={arrow} />
                                <div className="flex flex-row">
                                    <img src={four} />
                                    <div className="ml-[20px] w-[200px]"> Start working on the project as per the contract terms. </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="5" className="flex flex-col md:flex-row justify-evenly md:justify-center md:items-center px-[20px] md:px-[70px]">
                    <div className="py-[30px] flex flex-col mx-auto md:mx-auto justify-center font-serif font-times">
                        <div className="text-[36px] md:text-[36px] font-black leading-[42px]">
                            <div> Find Your Perfect 
                                <span className="md:hidden"> Contractor Match </span>
                            </div>
                            <div className="hidden md:block"> Contractor Match </div>
                        </div>
                        <div className="text-[18px] flex flex-col w-[350px] mt-[33px] md:mx-0 leading-[30px]"> 
                            <div> "Discover Your Dream Team - Choose Contractors You'll Love!" </div> 
                        </div>
                        {/* <div className="my-[11px] italic text-[18px] w-[350px] md:mx-0 font-medium">
                            <a href="/signup" className="text-[#1270B0] hover:text-[#1224b0]"> Sign up to Radici </a>
                        </div> */}
                    </div>
                    <div className="md:mx-auto md:w-[40%]">
                        <img data-aos-duration="700" data-aos="fade-left" src={ moneyHouse } />
                    </div>
                </div>
                <div id="6" className="flex flex-col md:flex-row justify-evenly md:justify-center md:items-center px-[20px] md:px-[70px] md:mt-[70px] font-serif font-times">
                    <div className="hidden md:block mx-auto w-[45%]">
                        <img data-aos-duration="700" data-aos="fade-right" className="w-[90%]" src={ offices } />
                    </div>
                    <div className="py-[30px] flex flex-col mx-auto md:mx-auto justify-center">
                        <div className="text-[36px] md:text-[36px] font-black leading-[42px]">
                            <div> No Surprises, </div>
                            <div> Only Certainty </div>
                        </div>
                        <div className="md:hidden px-auto my-[3vh]">
                            <img data-aos-duration="700" data-aos="fade-right" className="-ml-6" src={ offices } />
                        </div>
                        <div className="text-[18px] flex flex-col w-[350px] mt-[33px] md:mx-0 leading-[30px]"> 
                            <div> "Budget with Confidence - Know the Cost Before You Begin!" </div>
                        </div>
                        <button className="my-[11px] italic text-[18px] bg-[#412d7c] w-[160px] h-10 rounded ml-[90px] mt-[30px] font-medium">
                            <a href="/signup" className="text-[#fff]"> Sign up to Radici </a>
                        </button>
                    </div>
                </div>
            </section>
            <footer className="hidden md:block">
                <div className="flex flex-row justify-between relative">
                    <div className="flex flex-col justify-center items-start md:p-3 absolute top-[10vh] left-[3vw] lg:relative">
                        <img className="w-[100px] md:w-[190px]" src={ LogoImg } />
                    </div>
                    <img className="md:h-[20vh] -z-[50] absolute left-[28vw] md:left-[30vw] top-[34vh] md:top-[25vh]" src={ footerImg }/>
                </div>
            </footer>
            <footer className='md:hidden flex flex-col items-center pt-[10vh]'>
                <img className="w-[100px] mb-[5vh]" src={ LogoImg } />
                <img className="" src={ footerImg }/>
            </footer>
        </div>
    )
}


export default LandingPage;
