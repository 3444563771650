import React, { useState } from 'react';
import icon from '../assets/search.svg'

const countries = [
    'Country 1',
    'Country 2',
    'Country 3',
    // Add more countries as needed
];

const Search = () => {
    const [country, setCountry] = useState('');
    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };
    return (
        <div className='flex flex-row justify-between  px-[30px]'>
            <div className="relative">
                <input
                    type="text"
                    placeholder="Search..."
                    className="w-[400px] border border-gray-300 rounded-md pl-10 pr-4 py-2 focus:outline-none focus:border-[#CEB0FF]"
                />
                <img
                    src={icon}
                    alt="Search icon"
                    className="absolute left-3 top-2.5 h-5 w-5"
                />
            </div>
            <select
                value={country}
                onChange={handleCountryChange}
                className='w-[320px] border border-gray-300 text-gray-400 rounded-md pl-4 pr-4 py-2 focus:outline-none focus:border-[#CEB0FF]'
            >
                <option className='text-gray-300' value="" disabled hidden>
                    Sort by:
                </option>
                {countries.map((country, index) => (
                    <option key={index} value={country}>
                        {country}
                    </option>
                ))}
            </select>
            <button className="bg-black text-[#CEB0FF] py-[8px] px-[70px] rounded-md">
                Export List
            </button>


        </div>

    );
};

export default Search;
