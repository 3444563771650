import React,{useState} from 'react';
import Pagnition from './pagnition'
import ContractModal from './ContractModal'



const Table = () => {
    // Sample user data (replace with your data)
    const users = [
        {
            id: 1,
            'Client ID': 1,
            Specification: 'Alice',
            'Start Date': '28',
            Duration: 'USA',
            Timestamps: 'Engineer',
            email: 'alice@example.com',
            status: 'Active',
        },
        {
            id: 2,
            'Client ID': 2,
            Specification: 'Bob',
            'Start Date': '35',
            Duration: 'Canada',
            Timestamps: 'Designer',
            email: 'bob@example.com',
            status: 'Inactive',
        },
        // Add more sample data in the same format as needed
        {
            id: 3,
            'Client ID': 3,
            Specification: 'Bob',
            'Start Date': '35',
            Duration: 'Canada',
            Timestamps: 'Designer',
            email: 'bob@example.com',
            status: 'Inactive',
        },
        {
            id: 4,
            'Client ID': 4,
            Specification: 'Bob',
            'Start Date': '35',
            Duration: 'Canada',
            Timestamps: 'Designer',
            email: 'bob@example.com',
            status: 'Inactive',
        },
        {
            id: 5,
            'Client ID': 4,
            Specification: 'Bob',
            'Start Date': '35',
            Duration: 'Canada',
            Timestamps: 'Designer',
            email: 'bob@example.com',
            status: 'Inactive',
        },


    ];
    const itemsPerPage = 5;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleRowClick = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };


    // Custom column headers
    const columnHeaders = ['Client ID', 'Specification', 'Start Date', 'Duration', 'Timestamps'];

    return (
        <div className='px-[30px]'>
            <table className="table-auto w-full h-[500px] mt-[30px] shadow-md ">
                <thead>
                    <tr className="bg-[#46257A26]  text-black">
                        <th className="px-4 py-2">#</th>
                        {columnHeaders.map((header, index) => (
                            <th key={index} className="px-4 text-left  py-2">
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, rowIndex) => (
                        <tr
                            key={user.id}
                            className={`${rowIndex % 2 === 0 ? 'bg-white' : 'bg-[#46257A26] '} mx-auto`}
                            onClick={() => handleRowClick(user)} style={{ cursor: 'pointer' }}
                        >
                            <td className=" px-4  py-2">{user.id}</td>
                            {columnHeaders.map((header, colIndex) => (
                                <td key={colIndex} className="px-4 py-2">
                                    {user[header]} {/* Use header directly */}
                                </td>
                            ))}

                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagnition data={users} itemsPerPage={itemsPerPage} />
            {isModalOpen && <ContractModal user={selectedUser} onClose={() => setIsModalOpen(false)} />}

        </div>

    );
};

export default Table;
