import React, { useState } from 'react';

const TablePagination = ({ data, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div>
  
      <div className="flex justify-between rounded-md  bg-black  mt-4 p-[10px]">
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className="bg-[#CEB0FF] px-4 py-2 rounded mr-2"
        >
          &larr; Previous
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            className={`mx-1 px-3 py-1 rounded ${
              currentPage === number ? 'bg-[#CEB0FF]  text-black' : 'bg-gray-300'
            }`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={nextPage}
          disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
          className="bg-[#CEB0FF]  px-4 py-2 rounded ml-2"
        >
          Next &rarr;
        </button>
      </div>
    </div>
  );
};

export default TablePagination;
