import { useState, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom"; 

//import beginning pages here
import LandingPage from './BeginningPages/LandingPage';
import Signin from "./BeginningPages/Signin";
import Signup from "./BeginningPages/Signup";
import AboutUs from "./BeginningPages/AboutUsPage";
import CreateNewPassword from "./BeginningPages/CreateNewPassword";
import ResetPassword from "./BeginningPages/ResetPassword";
import MainLayout from "./BeginningPages/components/MainLayout.jsx";
import SignupSelectedRole from "./BeginningPages/SignupSelectedCat .jsx";
import Waitlist from "./BeginningPages/Waitlist.jsx";

//import contractor pages here
import CompleteProfileFirst from "./ContractorPages/CompleteContractorProfile_1.jsx";
import CompleteProfileSecond from "./ContractorPages/CompleteContractorProfile_2.jsx";
import ContractorHome from "./ContractorPages/ContractorHomePage.jsx";
import ViewContract from "./ContractorPages/ViewContracts.jsx";
import BidStatus from "./ContractorPages/BidStatus.jsx";
import ProjectReminders from "./ContractorPages/ProjectReminders.jsx";
import ContractorMainLayout from "./ContractorPages/components/ContractorMainLayout.jsx";
import ScheduleMeeting from "./ContractorPages/ScheduleMeeting.jsx";
import UpdateProgressReport from "./ContractorPages/UpdateProgressReport.jsx";
import Feedback from "./ContractorPages/Feedback.jsx";
import Complaint from "./ContractorPages/FileAComplaint.jsx";
import EditProfileFirst from "./ContractorPages/EditContractorProfile_1.jsx";
import EditProfileSecond from "./ContractorPages/EditContractorProfile_2.jsx";
import BidRequest from "./ContractorPages/BidRequest.jsx";

//import client pages here
import CompProf from "./ClientPages/CompProf";
import Contracts from './ClientPages/Contracts';
import Bid from "./ClientPages/Bid.jsx";
import Progress from "./ClientPages/ProgressReports.jsx";
import Deposit from './ClientPages/Deposit.jsx';
import ClientFeedback from './ClientPages/ClientFeedback.jsx'
import ClientComplaint from './ClientPages/ClientCompliant.jsx';
import ClientProfile from './ClientPages/ClientProfile.jsx';
import EditProfile from "./ClientPages/EditProfile.jsx";
import ContractRequest from "./ClientPages/ContractRequest.jsx";
import ClientHome from "./ClientPages/ClientHome.jsx";
import ClientMainLayout from "./ClientPages/components/client-main-layout.jsx";

//import field agent pages here
import FieldAgentMainLayout from "./FieldAgentPages/components/field-agent-main-layout.jsx";
import FieldAgentHome from "./FieldAgentPages/field-Agent-homepage.jsx";
import ViewFieldAgentContracts from "./FieldAgentPages/View-Field-Agent-Contracts.jsx";
import FieldAgentProfile from "./FieldAgentPages/field-agent-profile.jsx";
import EditFieldAgentProfile from "./FieldAgentPages/edit-field-agent-profile.jsx";
import UpdateAgentProgressReport from "./FieldAgentPages/update-agent-progress-report.jsx";


import './App.css';

import 'primeicons/primeicons.css';
import ContractorProfile from "./ContractorPages/ContractorProfile.jsx";

import SignInContextProvider from "./Contexts/SignInContext.jsx";
import { RoleSelectionProvider } from "./Contexts/SelectedRoleContext.jsx";
import { SignInContext } from "./Contexts/SignInContext.jsx";


function App() {
  return (
    <>
      <SignInContextProvider>
        <RoleSelectionProvider>
          <AppRoutes />
        </RoleSelectionProvider>
      </SignInContextProvider>
    </>
  );
}


function AppRoutes() {
  const { userRole } = useContext(SignInContext);
  
  return (
    <>
      <Routes>
      <Route path="/" element={<MainLayout />}>
          <Route path="/" element={ <LandingPage /> } />
          <Route path="/aboutus" element={ <AboutUs /> } />
        </Route>
        <Route path="/signin" element={ <Signin /> } />
        <Route path="/signup" element={ <Signup /> } />
        <Route path="/aboutus" element={ <AboutUs /> } />
        <Route path="/waitlist" element={ <Waitlist /> } />
        <Route path="/signuproleselected" element={ <SignupSelectedRole /> } />
        <Route path="/aboutus/signup" element={ <Signup /> } />
        <Route path="/aboutus/signin" element={ <Signin /> } />
        <Route path="/signin/resetPassword" element={ <ResetPassword /> } />
        <Route path="/signin/resetPassword/createNewPassword" element={ <CreateNewPassword /> } />


        {userRole === 'contractor' ? (
          <Route path="/contractor" element={<ContractorMainLayout />}>
            {/* Contractor routes */}
            <Route path="/contractor/Home" element={<ContractorHome />} />
            <Route path="/contractor/Profile" element={ <ContractorProfile /> } />
            <Route path="/contractor/completeContractorProfile-phase_1" element={ <CompleteProfileFirst /> } />
            <Route path="/contractor/completeContractorProfile-phase_2" element={ <CompleteProfileSecond /> } />
            <Route path="/contractor/viewcontract" element={ <ViewContract /> } />
            <Route path="/contractor/bidstatus" element={ <BidStatus /> } />
            <Route path="/contractor/projectreminder" element={ <ProjectReminders /> } />
            <Route path="/contractor/schedulemeeting" element={ <ScheduleMeeting /> } />
            <Route path="/contractor/updateprogressreport" element={ <UpdateProgressReport /> } />
            <Route path="/contractor/feedback" element={ <Feedback /> } />
            <Route path="/contractor/complaint" element={ <Complaint /> } />
            <Route path="/contractor/editprofile-phase_1" element={ <EditProfileFirst /> } />
            <Route path="/contractor/editprofile-phase_2" element={ <EditProfileSecond /> } />
            <Route path="/contractor/bidrequest" element={ <BidRequest /> } />
          </Route>
         ) : userRole === 'client' ? (
          <Route path="/client">
            {/* Client routes */}
            <Route path="/client" element={<ClientMainLayout />}>
              <Route path="/client/completeprofile" element={ <CompProf/> } />
              <Route path="/client/contracts" element={ <Contracts/> } />
              <Route path="/client/bidrequests" element = {<Bid/>}/>
              <Route path="/client/progressreports" element = {<Progress/>} />
              <Route path = '/client/makedeposit' element={<Deposit/>} />
              <Route path="/client/completeprofile" element={ <CompProf/> } />
              <Route path= '/client/feedback' element={<ClientFeedback/>}/>
              <Route path='' element={<ClientComplaint/>}/>
              <Route path='/client/profile' element={<ClientProfile/>}/>
              <Route path='/client/editprofile' element={<EditProfile/>}/>
              <Route path="/client/contractrequest" element={<ContractRequest/>}/>
              <Route path="/client/home" element={<ClientHome/>}/>
            </Route>
          </Route>
         ) : userRole === 'field-agent' ? (
          <Route path="/field_agent" element={<FieldAgentMainLayout />}>
            <Route path="/field_agent/home" element={<FieldAgentHome />} />
            <Route path="/field_agent/contracts" element={<ViewFieldAgentContracts />} />
            <Route path="/field_agent/profile" element={<FieldAgentProfile />} />
            <Route path="/field_agent/edit_profile" element={<EditFieldAgentProfile />} />
            <Route path="/field_agent/update_progress_report" element={<UpdateAgentProgressReport />} />
          </Route>
         )
         :
         (
          <Route path="/" element={<Navigate to="/signin" replace />} /> 
         )} 
      </Routes>
    </>
  );
}

export default App;
