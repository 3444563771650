import contractionsite from "./assets/constractionSite.png";

export const contractOptions = [
    { option: "Contracts", link: "/contractor/viewcontract"},
    { option: "Bid Requests", link: "/contractor/bidrequest"},
    { option: "Bid Status", link: "/contractor/bidstatus"},
    { option: "Progress Reports", link: "/contractor/updateprogressreport"},
    { option: "Schedule a Meeting", link: "/contractor/schedulemeeting"}
];

export const profileOption = [
    {
        profileOption: "Complete profile",
        link: '/contractor/completeContractorProfile-phase_1'
    },
    {
        profileOption: "Edit profile",
        link: '/contractor/editprofile-phase_1'
    }
];

export const feedbacks = [
    {
        id: 1,
        time: "2:30pm",
        message: "Plenty plenty talk blah yes yes yes okay sharp",
        media: ""
    },
    {
        id: 2,
        time: "1:00am",
        message: "Use the inline-datepicker and data-date data attributes to initialize and set the default date for an inline datepicker inside a block element such as a div.",
        media: ""
    },
    {
        id: 3,
        time: "9:33pm",
        message: "With the Tailwind Datepicker you can also add extra options using the custom data attributes developed by Flowbite based on a Vanilla JS library.",
        media: contractionsite
    }
]