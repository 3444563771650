import React, { useState } from 'react';
import { SingUpGoogle, Submitbtn } from './components/buttons';
import InputTextField from './components/inputTextField';
import PasswordField from './components/passwordField';
import RememberMe from './components/rememberMe';
import LogoImg from "./assets/Logo.png";
import ChooseRole from './components/ChooseRole';

export default function Signup() {
  return (
    <div className='flex flex-col-reverse md:flex-row gap-[50px] md:max-h-[10%] md:gap-0 items-center w-full lg:flex-col-reverse'>
      <div className="md:absolute md:-left-[40%] md:-top-[30vh] md:p-3 lg:relative">
        <img className="w-[100px] md:w-[190px]" src={ LogoImg } />
    </div>
      <div className='flex flex-col justify-center md:mt-[30px] mx-auto max-w-[90%] items-center gap-2 p-4'>
        <div className='text-[32px] mb-[20px] text-center account'>Create Account</div>
        <SingUpGoogle properties={{label:"Sign up with"}}/>
        <div className='w-[100%] mt-4 flex items-center gap-5'>
          <hr className='bg-[#412d7c] h-[2px] w-[50%]'/>
          <span className='text-xs'>or</span>
          <hr className='bg-[#412d7c] h-[2px] w-[50%]'/>
        </div>
        <div className='text-sm font-semibold'>Fill in your details below</div>
        <ChooseRole/>
        <InputTextField label="First Name*" />
        <InputTextField label="Last Name*" />
        <InputTextField label="Email*"/>
        <PasswordField feedback={true} label="Password*"/>
        <PasswordField feedback={false} label="Confirm Password*"/>
        <RememberMe />
        <Submitbtn  properties={{ label: 'Sign up' }} />
        <div className='text-center text-[9px]'>By continuing you accept our standard <a className='text-[#1270B0]' href='/'>terms</a> and <a className='text-[#1270B0]'  href='/'>conditions</a> and agree to <a className='text-[#1270B0]'  href='/'>our policies</a></div>
        <div className='text-sm mt-4'>Already have an account? <a className='text-[#1270B0]'  href="/signIn">Sign In</a></div>
      </div>

    </div>
  )
}