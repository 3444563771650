import React, { useEffect,useState, createContext } from "react";

export const SignInContext = createContext();

export default function SignInContextProvider({ children }){
    const [userRole, setUserRole] = useState('');
    
    const updateRole = (newRole) => {
        setUserRole(newRole);
        localStorage.setItem('roleContextData', newRole);
    };
    
    useEffect(() => {
        const savedRole = localStorage.getItem('roleContextData'); 
        if (savedRole) {
        setUserRole(savedRole);
        }
    }, []);

    return (
        <SignInContext.Provider value={{userRole, setUserRole: updateRole}} >
            { children }
        </SignInContext.Provider>
    )
}