import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalenImage from '../assets/calendar.svg'; // Import your custom calendar image

const DateTimePicker = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <div className='flex flex-row space-x-10'>
      {/* Start Date Picker */}
      <div className="relative mb-3 w-[280px] h-[40px] border border-gray-300 text-black rounded-md pl-4 pr-4 py-2 focus:outline-none focus:border-[#CEB0FF]">
        <div className="relative">
          <input
            type="text"
            className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
            id="startDate"
            value={selectedDate ? selectedDate.toDateString() : ''}
            readOnly
          />
          <div className="absolute right-3 top-0 cursor-pointer" style={{ zIndex: 1 }}>
            <img
              src={CalenImage}
              alt="Calendar"
              className="h-6 w-6 text-primary"
              onClick={() => document.getElementById('startDatePicker').click()}
            />
          </div>
        </div>
        <DatePicker
          id="startDatePicker"
          selected={selectedDate}
          onChange={handleDateChange}
          className="hidden"
          selectsStart
          startDate={selectedDate}
          endDate={endDate}
          placeholderText="Start Date"
        />
        {/* Conditional rendering for hiding label when selectedDate is not null */}
        {selectedDate ? null : (
          <label
            htmlFor="startDate"
            className="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
          >
            Start Date
          </label>
        )}
      </div>

      {/* End Date Picker */}
      <div className="relative mb-3 w-[280px] h-[40px] border border-gray-300 text-gray-400 rounded-md pl-4 pr-4 py-2 focus:outline-none focus:border-[#CEB0FF]">
        <div className="relative">
          <input
            type="text"
            className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
            id="endDate"
            value={endDate ? endDate.toDateString() : ''}
            readOnly
          />
          <div className="absolute right-3 top-0 cursor-pointer" style={{ zIndex: 1 }}>
            <img
              src={CalenImage}
              alt="Calendar"
              className="h-6 w-6 text-primary"
              onClick={() => document.getElementById('endDatePicker').click()}
            />
          </div>
        </div>
        <DatePicker
          id="endDatePicker"
          selected={endDate}
          onChange={handleEndDateChange}
          className="hidden"
          selectsEnd
          startDate={selectedDate}
          endDate={endDate}
          minDate={selectedDate}
          placeholderText="End Date"
        />
        {endDate ? null : (
          <label
            htmlFor="startDate"
            className="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
          >
            End Date
          </label>
        )}
      </div>
    </div>
  );
};

export default DateTimePicker;
