import React, { useState } from "react";
import { Submitbtn } from "./components/buttons";
import InputTextField from "./components/inputTextField";
import LogoImg from "./assets/Logo.png";


const ResetPassword = () => {
    const [email, setEmail] = useState('');
    return (
        <div className="flex flex-col-reverse md:flex-row p-5">
            <div className="flex justify-center md:absolute md:top-[110%] md:left-[10%] md:h-0 mt-[40px] md:w-[15%]">
            <div className="md:absolute md:-left-[40%] md:-top-[45vh] md:p-3 lg:relative">
                <img className="w-[100px] md:w-[190px]" src={ LogoImg } />
            </div>
            </div>
            <div className="flex flex-col justify-center items-center mx-auto w-full md:w-2/3 mt-[100px]">
                <div className="text-center text-3xl mb-4 resetPassword"> Reset Password </div>
                <p className="text-center mb-[70px] "> Please enter the email associated with the account for which the password will be reset </p>
                <div className="w-[80%] md:w-[50%]">
                    <InputTextField properties={{label:"Email*"}}/>
                    <a href="/signin/resetPassword/createNewPassword">
                        <Submitbtn properties={{ label: 'Get reset link' }} />
                    </a>
                </div>
            </div>
        </div>
    )
}


export default ResetPassword;