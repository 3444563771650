import React, { useState } from 'react';
import browse from './assets/browse.jpg';
import doc from './assets/doc.svg';
import profilePic from "./assets/profilepic.svg";

const EditProfileSecond = () => {
    const [ProfilePic, setProfilePic] = useState(null);
    const [Media, setMedia] = useState(null);

    const handleProfilePicChangeProfile = (e) => {
        const picFile = e.target.files[0];
        setProfilePic(picFile);
    };

    const handleDocumentChangeProfile = (e) => {
        const docFile = e.target.files[1];
        setMedia(docFile);
    };


    return (
        <div>
            <div className='flex flex-col px-[30px] pt-[14vh]'>
                <div className=' h-[80px] flex flex-col justify-between'>
                    <p className='text-[26px] font-bold'>Complete profile</p>
                    <p className='text-[14px] font-semibold text-[#00000099]'>Please provide the required document below </p>
                </div>

                <div className='bg-[#46257A26] px-[40px] py-[20px]'>
                    <div className='flex flex-col'>
                        <form className='flex flex-row justify-between h-[250px] w-full'>
                            <div className="mt-[30px]">
                                <text> Profile Picture* </text>
                                <div className="h-[25vh] w-[30vw] bg-[#fff] relative">
                                    <div className='flex flex-col items-center w-[65%] h-16'>
                                        <img className='w-1/2 mx-auto pt-2' src={ profilePic }/>
                                        <text className='font-light text-center'> profile.pdf </text>
                                    </div>
                                    <div className='absolute bottom-0 right-0'>
                                        <label htmlFor="fileInput" className="cursor-pointer flex bg-white  md:w-[140px] rounded-md p-2 ">
                                            {ProfilePic ? (
                                                <div className='flex justify-end  items-center md:w-[380px]'>
                                                    <img
                                                        src={URL.createObjectURL(ProfilePic)}
                                                        alt="Selected file"
                                                        className="w-40 h-40 object-cover rounded-md"
                                                    />
        
                                                </div>
        
                                            ) : (
                                                <div className=' flex justify-end  items-center w-[320px] md:w-[380px]'>
                                                    <img
                                                        src={browse}
                                                        alt="Choose File"
                                                        className="w-[110px]  object-cover"
                                                    />
        
                                                </div>
        
                                            )}
                                        </label>
                                        <input
                                            id="fileInput"
                                            className="hidden"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleProfilePicChangeProfile}
                                        />
        
                                    </div>
                                </div>
                            </div>
                            <div className="mt-[30px]">
                                <text> Upload License Document* </text>
                                <div className="h-[25vh] w-[30vw] bg-[#fff] relative">
                                    <div className='flex flex-col w-[85%] h-16'>
                                        <img className='w-1/2 mx-auto' src={ doc }/>
                                        <text className='font-light text-center -mt-6'> license.pdf </text>
                                    </div>
                                    <div className='absolute bottom-0 right-0'>
                                        <label htmlFor="fileInput" className="cursor-pointer flex bg-white w-[140px] rounded-md p-2 ">
                                            {Media ? (
                                                <div className='flex justify-end  items-center md:w-[380px]'>
                                                    <img
                                                        src={URL.createObjectURL(Media)}
                                                        alt="Selected file"
                                                        className="w-40 h-40 object-cover rounded-md"
                                                    />
        
                                                </div>
        
                                            ) : (
                                                <div className=' flex justify-end  items-center w-[320px] md:w-[380px]'>
                                                    <img
                                                        src={browse}
                                                        alt="Choose File"
                                                        className="w-[110px]  object-cover"
                                                    />
        
                                                </div>
        
                                            )}
                                        </label>
                                        <input
                                            id="fileInput"
                                            className="hidden"
                                            type="file"
                                            accept=".jpg, .png, .jpeg, .svg, .doc, .pdf"
                                            onChange={handleDocumentChangeProfile}
                                        />
        
                                    </div>
                                </div>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
            <div className=' px-[30px] h-[60px] flex flex-row fixed bottom-0 right-0 justify-end items-center space-x-8'>
                <button className='bg-purple-200 px-[24px] py-[10px] rounded-md'> Cancel </button>
                <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> Save </button>
            </div>

        </div>
        
        

    );
};

export default EditProfileSecond;
