import React from 'react';
import {useState} from 'react';
import { InputText } from 'primereact/inputtext';
export default function InputTextField({ label, value, type, setValueFunc }) {
  return (    
    <div className="w-[100%]">
    <label className='text-xs' htmlFor="username">{label}</label>
    <InputText className='h-9' id="username" type={type} value={value} onChange={setValueFunc}/>
    </div>
  
  )
}
