import React, { useState } from 'react';

import icon from './assets/search.svg'
import doc from './assets/doc.svg'
import small from './assets/sml.svg'
import big from './assets/big.svg'

const ClientHome = () => {
    return (
        <div>
            <p className="pt-[100px] text-[26px] px-[30px] py-[10px] font-bold">Welcome Back User</p>
            <div className='px-[30px]'>
                <div className="relative ">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="w-[400px] border border-gray-300 rounded-md pl-10 pr-4 py-2 focus:outline-none focus:border-[#CEB0FF]"
                    />
                    <img
                        src={icon}
                        alt="Search icon"
                        className="absolute left-3 top-2.5 h-5 w-5"
                    />
                </div>

            </div>
          
            <p className="pt-[10px] text-[20px] px-[30px] py-[10px] font-bold">What's New?</p>
            <div className='mx-[30px] h-[74vh] mb-8 bg-[#46257A26]'>
                <div className='flex flex-row p-[20px] justify-between border-b border-white ]'>
                    <p className='font-bold'> 3-bedroom flat</p>
                    <p className='font-bold'>ID 1234</p>

                </div>
                <div className='flex flex-row  w-full'>
                    <div className='w-[700px] border-r border-white flex justify-center pt-[30px]'>
                        <div className='flex items-start flex-col space-y-8'>
                            <p>Status: <span className='text-green-500'>Accepted</span></p>
                            <p> Awarded to: <span>Contractor 123</span></p>
                            <p>Bid Duration: <span> 6 months</span></p>
                            <p>Bid Price: <span>50000</span></p>
                            <p>Contractor's Name: <span>Kwadwo</span></p>
                            <div className='flex flex-row space-x-5'>
                                <p>Document: </p>
                                <img src={doc} className='w-[120px]'/>
                            </div>
                                

                            </div>


                    </div>
                    <div>
                        <div className='flex flex-col justify-between  w-[800px] space-y-[20px] pl-[100px] pt-[30px] '>
                            <div className='flex flex-row space-x-10 justify-between'>
                                <img src={small}/>
                                <img src={small}/>
                                <img src={small}/>

                            </div>
                            <div className='flex flex-row space-x-10 justify-between '>
                                <img src={small}/>
                                <img src={small}/>
                                <img src={small}/>


                            </div>
                            
                        </div>
                        <div className='mt-[20px]'>
                            <img src={big} className='w-[600px] h-[120px] object-cover pl-[100px]'/>

                        </div>

                    </div>
                </div>

            </div>

        </div>
    );
};

export default ClientHome;