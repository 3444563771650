import React, { useEffect, createContext, useState } from 'react';

export const RoleSelectionContext = createContext();

export const RoleSelectionProvider = ({ children }) => {
  const [signupRole, setSignupRole] = useState('');

  const updateSignUpRole = (newRole) => {
    setSignupRole(newRole);
    localStorage.setItem('signuproleContextData', newRole);
  };
  
  useEffect(() => {
      const savedRole = localStorage.getItem('signuproleContextData'); 
      if (savedRole) {
      setSignupRole(savedRole);
      }
  }, []);

  return (
    <RoleSelectionContext.Provider value={{ signupRole, setSignupRole: updateSignUpRole }}>
      {children}
    </RoleSelectionContext.Provider>
  );
};