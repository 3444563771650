// ... (existing imports and component definition)
import React,{useState} from 'react';
import Pagnition from './pagnition'
import fileText from '../assets/file-text.svg'
import BidModal from './BidModal';

const Table = () => {
    // ... (existing data and constants)
    const users = [
        {
            id: 1,
            name: 'Alice',
            age: 28,
            country: 'USA',
            occupation: 'Engineer',
            email: 'alice@example.com',
            status: 'Active',
        },
        {
            id: 2,
            name: 'Bob',
            age: 35,
            country: 'Canada',
            occupation: 'Designer',
            email: 'bob@example.com',
            status: 'Inactive',
        },
        {
            id: 3,
            name: 'Akosua',
            age: 28,
            country: 'USA',
            occupation: 'Engineer',
            email: 'alice@example.com',
            status: 'Active',
        },
        {
            id: 4,
            name: 'Bob',
            age: 35,
            country: 'Canada',
            occupation: 'Designer',
            email: 'bob@example.com',
            status: 'Inactive',
        }, {
            id: 5,
            name: 'Bob',
            age: 35,
            country: 'Canada',
            occupation: 'Designer',
            email: 'bob@example.com',
            status: 'Inactive',
        }
    ]

    const itemsPerPage = 5;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleRowClick = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };
    


    const columnHeaders = ['Name', 'Age', 'Country', 'Occupation', 'Email', 'Status'];

    const handleProfilePictureChange = (event) => {
        const file = event.target.files[0];
        // Handle profile picture change logic here
        // This might involve updating the user data with the new file information
    };

    return (
        <div className='px-[30px]'>
            <table className="table-auto w-full h-[500px] mt-[30px] shadow-md ">
                <thead>
                    <tr className="bg-[#46257A26]  text-black">
                        <th className="px-4 py-2">#</th>
                        {columnHeaders.map((header, index) => (
                            <th key={index} className="px-4 text-left  py-2">
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, rowIndex) => (
                        <tr
                            key={user.id}
                            className={`${rowIndex % 2 === 0 ? 'bg-white' : 'bg-[#46257A26] '} mx-auto`}
                            onClick={() => handleRowClick(user)} style={{ cursor: 'pointer' }}
                            
                        >
                            <td className="px-4 py-2">{user.id}</td>
                            {columnHeaders.map((header, colIndex) => (
                                <td key={colIndex} className="px-4 py-2">
                                    {colIndex === 2 ? (
                                        <div className=''>
                                            {user.profilePicture ? (
                                                <img
                                                    src={URL.createObjectURL(user.profilePicture)}
                                                    alt="Selected file"
                                                    className="w-40 h-40 object-cover rounded-md"
                                                />
                                            ) : (
                                                <img
                                                    src={fileText}
                                                    alt="Choose File"
                                                    className="w-[80px] object-cover"
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        user[header.toLowerCase()]
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            <Pagnition data={users} itemsPerPage={itemsPerPage} />
            {isModalOpen && <BidModal user={selectedUser} onClose={() => setIsModalOpen(false)} />}

        </div>
    );
};

export default Table;