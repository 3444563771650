import React from 'react'
import doc from '../assets/doc.svg'

const BidModal = ({visible,onClose})=>{
    const handleOnClose = () =>{
        
        onClose()
    }
    return(
        <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center' id='container'>
            <div className='bg-white p-[30px]  w-[660px] h-[540px] rounded overflow-hidden animate-modal'>
                <p className='font-bold text-xl'> Bid Request - <span>Contract 1236</span></p>
                <div className='h-[390px] mt-[10px] rounded bg-[#46257A26] '>
                    <p className='pl-[20px] text-[20px] font-bold border-b border-white pt-[20px]'>Contract ID 1246</p>
                    <div className='flex flex-col justify-center items-center pt-[30px]'>
                        <div className='flex items-start flex-col space-y-5'>
                            <p>Status: <span className='text-green-500'>Accepted</span></p>
                            <p> Awarded to: <span>Contractor 123</span></p>
                            <p>Bid Duration: <span> 6 months</span></p>
                            <p>Bid Price: <span>50000</span></p>
                            <p>Contractor's Name: <span>Kwadwo</span></p>
                            <div className='flex flex-row space-x-5'>
                                <p>Document: </p>
                                <img src={doc} className='w-[120px]'/>
                            </div>
                            

                        </div>
                        
                    </div>

                </div>
                <div className=' md:px-[30px] pt-[5px] h-[60px] flex flex-row w-full md:w-full justify-end items-center space-x-8'>
                    <button className='bg-purple-200 px-[24px] py-[10px] rounded-md' onClick={onClose}> Back </button>
                    <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> Export </button>
                </div>
            </div>
        </div>
    
    )
}

export default BidModal