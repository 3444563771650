import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { Datepicker } from 'flowbite-react';
import browse from './assets/browse.jpg';

const BidRequest = () => {
    const [date, setDate] = useState(new Date());
    const [Media, setMedia] = useState(null);

    const navigate = useNavigate();

    const handleMediaChange = (e) => {
        const file = e.target.files[0];
        setMedia(file);
    };
    return (
        <div>
            <div className='flex flex-col px-[30px] pt-[14vh]'>
                <div className=' h-[90%] flex flex-col justify-between pb-[30px]'>
                    <text className='text-[26px] font-bold mb-5'> Make Bid Request </text>
                    <text className="font-thin"> Make a bid on the contract selected </text>
                    <div className="bg-[#46257A26] h-[85vh]">
                        <div className="bg-[#BCB0CF] font-bold flex flex-row px-12 h-12 items-center">
                            <span> Constract ID - <text className="text-[#1270B0]"> 123456 </text> </span>
                        </div>
                        <div className="pl-[40px] pr-[100px]">
                            <form className="flex flex-row justify-between">
                                <div className="flex flex-col w-[35%] mt-[30px]">
                                    <label className="flex flex-col mt-5">
                                        Bid Price*
                                        <div className="flex items-center relative">
                                            <text className="font-bold absolute left-[1vw]"> $ </text>
                                            <input type="number" className="w-full h-[40px] pl-10 rounded-[5px] border-[#686868]" />
                                        </div>
                                    </label>
                                    <label className="flex flex-col mt-5">
                                        Start Date*
                                        <div class="relative">
                                            <Datepicker minDate={new Date(2023, 0, 1)} maxDate={new Date(2023, 3, 30)} />
                                        </div>
                                    </label>
                                    <label className="flex flex-col mt-5">
                                        End Date*
                                        <div>
                                        <Datepicker minDate={new Date(2023, 0, 1)} maxDate={new Date(2023, 3, 30)} />
                                        </div>
                                    </label>
                                </div>
                                <div className="mt-[30px]">
                                    <label className="flex flex-col mt-5">
                                        Issues/Concern*
                                        <textarea
                                            name="issues"
                                            defaultValue="Write your concerns here"
                                            rows={7}
                                            cols={40}
                                        />
                                    </label>
                                    <div className="mt-[4vh]">
                                        <text> Upload Media* </text>
                                        <div className="h-[15vh] w-[30vw] bg-[#fff] relative">
                                            <div className='absolute bottom-0 right-0'>
                                                <label htmlFor="fileInput" className="cursor-pointer flex bg-white  md:w-[380px] rounded-md p-2 ">
                                                    {Media ? (
                                                        <div className='flex justify-end  items-center md:w-[380px]'>
                                                            <img
                                                                src={URL.createObjectURL(Media)}
                                                                alt="Selected file"
                                                                className="w-20 h-20 object-cover rounded-md"
                                                            />
                
                                                        </div>
                
                                                    ) : (
                                                        <div className=' flex justify-end  items-center w-[320px] md:w-[380px]'>
                                                            <img
                                                                src={browse}
                                                                alt="Choose File"
                                                                className="w-[110px]  object-cover"
                                                            />
                
                                                        </div>
                
                                                    )}
                                                </label>
                                                <input
                                                    id="fileInput"
                                                    className="hidden"
                                                    type="file"
                                                    accept=".jpg, .png, .jpeg, .svg, .doc, .pdf"
                                                    onChange={handleMediaChange}
                                                />
                
                                                </div>
                                        </div>
                                    </div>
                                </div> 
                                <div className=' px-[30px] h-[60px] w-full flex flex-row fixed bottom-0 right-0 justify-end bg-[#fff] items-center space-x-8'>
                                    <button className='bg-purple-200 px-[24px] py-[10px] rounded-md'> Cancel </button>
                                    <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'
                                    onClick={()=>navigate("/contractor/bidstatus")}
                                    > Save </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BidRequest;