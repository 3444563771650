import React, { useState } from "react";



const paymentMode = [
    'Cash',
    'Mobile money',
    'Visa',
    // Add more countries as needed
];

const Deposit = ({ onSubmit }) => {
    const [meetingDate, setMeetingDate] = useState('')
    const [meetingDetails, setMeetingDetails] = useState('')
    const [meetingDetails1, setMeetingDetails1] = useState('')
    const [contractSigningDetails, setContractSigningDetails] = useState('')
    const [contractSigningDetails1, setContractSigningDetails1] = useState('')
    const [contractSigningDetails2, setContractSigningDetails2] = useState('')
    const [amount, setAmount] = useState('')
    const [payment, setPayment] = useState('')
    const [account, setAccount] = useState('')


    const handleMeetingDate = (e) => {
        setMeetingDate(e.target.value);
    };
    const handleMeetingDetails = (e) => {
        setMeetingDetails(e.target.value);
    };

    const handleMeetingDetails1 = (e) => {
        setMeetingDetails1(e.target.value)
    }

    const handleContractSigningDetails = (e) =>{
        setContractSigningDetails(e.target.value)
    }

    const handleContractSigningDetails1 = (e) =>{
        setContractSigningDetails1(e.target.value)
    }

    const handleContractSigningDetails2 = (e) =>{
        setContractSigningDetails2(e.target.value)
    }

    const handleAmount = (e) => {
        setAmount(e.target.value)
    }

    const handlepayment = (e) => {
        setPayment(e.target.value)
    }
    const handleConfirmPayment = ()=>{
        console.log('payment confirmed')
    }



    return (
        <div>
            <p className="pt-[100px] text-[26px] px-[30px] py-[10px] font-bold">Make Deposit</p>

            <div className="px-[30px]">

                <form>
                    <div className="flex flex-row space-x-10  justify-between">


                        <div>
                            <p className="text-[#003B63] font-bold text-xl">Meeting Details</p>
                            <div className="bg-[#46257A26] h-[300px] w-[660px] px-[30px]" >


                                <div className="flex flex-col justify-between  h-[260px] py-[20px]">
                                    <div className='flex flex-col '>
                                        <label className='text-[#00000099] text-[14px]' >Meeting Date*</label>
                                        <div>
                                            <div >
                                                <input
                                                    className='outline-none h-[40px] w-[320px] md:h-[40px] md:w-[500px]  rounded-md'
                                                    type="text"
                                                    value={meetingDate}
                                                    onChange={handleMeetingDate}
                                                />
                                            </div>
                                        </div>




                                    </div>

                                    <div className='flex flex-col '>
                                        <label className='text-[#00000099] text-[14px]' >Meeting Details*</label>
                                        <div>
                                            <div >
                                                <input
                                                    className='outline-none h-[40px] w-[320px] md:h-[40px] md:w-[500px]  rounded-md'
                                                    type="text"
                                                    value={meetingDetails}
                                                    onChange={handleMeetingDetails}
                                                />


                                            </div>

                                        </div>




                                    </div>

                                    <div className='flex flex-col '>
                                        <label className='text-[#00000099] text-[14px]' >Meeting Details*</label>
                                        <div>
                                            <div >
                                                <input
                                                    className='outline-none h-[40px] w-[320px] md:h-[40px] md:w-[500px]  rounded-md'
                                                    type="text"
                                                    value={meetingDetails1}
                                                    onChange={handleMeetingDetails1}
                                                />


                                            </div>

                                        </div>




                                    </div>


                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="text-[#003B63] font-bold text-xl">Contract Signing Details</p>
                            <div className="bg-[#46257A26] h-[300px] w-[660px] px-[30px]" >


                                <div className="flex flex-col justify-between  h-[260px] py-[20px]">
                                    <div className='flex flex-col '>
                                        <label className='text-[#00000099] text-[14px]' >Contract Signing Details*</label>
                                        <div>
                                            <div >
                                                <input
                                                    className='outline-none h-[40px] w-[320px] md:h-[40px] md:w-[500px]  rounded-md'
                                                    type="text"
                                                    value={contractSigningDetails}
                                                    onChange={handleContractSigningDetails}
                                                />
                                            </div>
                                        </div>




                                    </div>

                                    <div className='flex flex-col '>
                                        <label className='text-[#00000099] text-[14px]' >Contract Signing Details*</label>
                                        <div>
                                            <div >
                                                <input
                                                    className='outline-none h-[40px] w-[320px] md:h-[40px] md:w-[500px]  rounded-md'
                                                    type="text"
                                                    value={contractSigningDetails1}
                                                    onChange={handleContractSigningDetails1}
                                                />


                                            </div>

                                        </div>




                                    </div>

                                    <div className='flex flex-col '>
                                        <label className='text-[#00000099] text-[14px]' >Contract Signing Details*</label>
                                        <div>
                                            <div >
                                                <input
                                                    className='outline-none h-[40px] w-[320px] md:h-[40px] md:w-[500px]  rounded-md'
                                                    type="text"
                                                    value={contractSigningDetails}
                                                    onChange={handleContractSigningDetails2}
                                                />


                                            </div>

                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    {/* third */}
                    <div className="w-full flex justify-end ">
                        <div className="bg-[#46257A26] h-[300px] w-[660px] px-[30px] mt-[50px] align-right flex flex-col justify-between py-[20px]">
                            <div className='flex flex-col '>
                                <label className='text-[#00000099] text-[14px]' >Amount*</label>
                                <div>
                                    <div >
                                        <input
                                            className='outline-none h-[40px] w-[320px] md:h-[40px] md:w-[380px]  rounded-md'
                                            type="text"
                                            value={amount}
                                            onChange={handleAmount}
                                        />


                                    </div>

                                </div>

                            </div>

                            <div className='flex flex-col '>
                                <label className='text-[#00000099] text-[14px]'>Payment Mode*</label>
                                <select value={payment} onChange={handlepayment} className='outline-none md:h-[40px]  h-[40px] w-[320px]  md:w-[380px] rounded-md'>
                                    <option value=""></option>
                                    {paymentMode.map((pay, index) => (
                                        <option key={index} value={payment}>
                                            {pay}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex  flex-col'>
                                <label className='text-[#00000099] text-[14px]' >Payment Mode*</label>
                                <div>
                                    <div >
                                        <input
                                            className='outline-none  h-[40px] w-[320px]  md:h-[40px] md:w-[380px] rounded-md'
                                            type="text"
                                            value={payment}
                                            onChange={handlepayment}
                                        />


                                    </div>

                                </div>

                            </div>
                            <div className="w-full flex justify-center ">
                                <button
                                    type="submit" // You can adjust the type to "submit" if used within a form
                                    className="bg-black hover:bg-[#FFEED5] hover:text-black text-[#FFEED5] font-bold py-2 px-4 rounded mx-auto"
                                    onClick={handleConfirmPayment}
                                >
                                    Confirm Payment
                                </button>
                            </div>
                        </div>
                    </div>

                </form>


            </div>

        </div>
    );
}

export default Deposit;