import React from 'react';

import Search from './components/search'
import BidTable from './components/BidTable'


const Bid = () =>{
    return(
        <div>
            <p  className="pt-[100px] text-[26px] px-[30px] py-[10px]"><spin className="font-bold pr-[10px]" >Bid Requests for</spin>Contracts 12345</p>
            <Search/>
            <BidTable/>
        </div>
    );
}

export default Bid;