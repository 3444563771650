import React,{useState} from 'react';
import small from '../assets/sml.svg'
import big from '../assets/big.svg'


const ReportModal = ({onClose})=>{

    const handleOnClose = () =>{
        
        onClose()
    }
    return(
        <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
            <div  className='bg-white p-[30px]   w-[680px] h-[730px] rounded overflow-hidden animate-modal'>
                <p className="font-bold text-xl mt-[48px]">Progress Report-Contract 1234</p>
                <div className='h-[550px] mt-[10px] rounded bg-[#46257A26]'>
                    <div className='flex w-full border-b border-white justify-between pt-[20px] px-[10px] '>
                        
                        <p className='font-bold'>
                            Status: <span className='text-green-500'>Accepted</span>
                        </p>
                        <p className='font-bold'>
                            70% Complete
                        </p>
                        <p className='font-bold'>
                            Contract 1245
                        </p>
                    </div>
                    <div className='flex flex-col'>
                        <div className='flex flex-row justify-between px-[20px] pt-[20px]'>
                            <div  className='w-[260px] h-[200px] bg-white rounded'>
                                <div className='bg-[#46257A26] flex flex-row justify-between p-[10px] '>
                                    <p className='font-bold text-[#003B63]'>Contractor Media</p>
                                    <p className='text-[#003B63]'>12-3-4</p>
                                </div>
                                <div className='flex justify-between pt-[20px] px-[20px]'>
                                    <img src={small} className='w-[120px]'/>
                                    <img src={small} className='w-[120px]'/>
                                </div>


                            </div>
                            <div className='w-[260px] h-[200px] bg-white rounded'>
                                <div className='bg-[#46257A26] flex flex-row justify-between p-[10px] '>
                                    <p className='font-bold text-[#003B63]'>Agent Media</p>
                                    <p className='text-[#003B63]'>12-3-4</p>
                                </div>
                                <div className='flex justify-between pt-[20px] px-[20px]'>
                                    <img src={small} className='w-[120px]'/>
                                    <img src={small} className='w-[120px]'/>
                                </div>

                            </div>

                        </div>
                        <div className='flex flex-row justify-between px-[20px] pt-[20px]'>
                            <div  className='w-[260px] h-[200px] bg-white rounded'>
                                <div className='bg-[#46257A26] flex flex-row justify-between p-[10px] '>
                                    <p className='font-bold text-[#003B63]'>Issues and concerns</p>
                                    <p className='text-[#003B63]'>12-3-4</p>
                                </div>
                                <div className='px-[5px] pt-[5px]'>
                                    <p>I am concerned about blah blah</p>
                                </div>

                            </div>
                            <div className='w-[260px] h-[200px] bg-white rounded'>
                                <div className='bg-[#46257A26] flex flex-row justify-between p-[10px] '>
                                    <p className='font-bold text-[#003B63]'>Satelite imagery</p>
                                    <p className='text-[#003B63]'>12-3-4</p>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <img src={big} className='w-[260px] h-[155px] object-cover'/>
                                </div>

                            </div>

                        </div>
                        
                    </div>
                    
                </div>
                <div className=' md:px-[30px] pb-[8px] pt-[5px] h-[60px] flex flex-row w-full md:w-full justify-end items-center space-x-8'>
                        <button className='bg-purple-200 px-[24px] py-[10px] rounded-md' onClick={onClose}> Back </button>
                        <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> Export </button>
                    </div>
                

                
            </div>
        </div>
    );
}

export default ReportModal;