import React, {useState} from "react";

import { feedbacks } from "./data";
import send from "./assets/send.svg";
import attachment from "./assets/attachment.svg";

const Complaint = () => {
    
    return (
        <div>
            <div className='flex flex-col px-[30px] pt-[14vh]'>
                <div className=' h-[90%] flex flex-col justify-between'>
                    <text className='text-[26px] font-bold mb-5'> File a Complaint </text>
                </div>
                <div className="bg-[#000] h-[110vh] flex justify-center items-center mb-12 rounded">
                    <div className="bg-[#fff] w-[98%] h-[96%] flex justify-end px-4 rounded relative">
                        <div className="w-full">
                            <div className="flex flex-col justify-end items-end">
                                {
                                    feedbacks.map((feedback) =>
                                    <div className="flex flex-col py-2">
                                        <span className="flex justify-between">
                                            <text> You </text>
                                            <text> { feedback.time } </text>
                                        </span>
                                        <span key={feedback.id} className="bg-[#E3DEEB] min-h-[15vh] px-5 py-2 rounded w-[60vw]"> 
                                        <div className="bg-[#fff] flex just-center items-center rounded">
                                            <img src={ feedback.media } alt="No media" className={`w-[40%] h-[90%] ${feedback.media==="" ? "hidden lg:hidden md:hidden" : ""}`}/> 
                                        </div>
                                        { feedback.message } 
                                        </span>
                                    </div>
                                    )
                                }
                            </div>
                            <div className="w-full absolute bottom-2">
                                <form>
                                    <span className="relative">
                                        <button type="submit" className="absolute right-2 w-10 pt-1"><img src={ send }/></button>
                                        <button className="absolute right-14 w-8 pt-2"><img src={ attachment }/></button>
                                        <input type="text" defaultValue="Type your message here..." className="w-[98%] border-[5px] border-[#46257A] rounded focus:border-[#46257A] border-r-[55px]"/>
                                    </span>
        
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' px-[30px] h-[60px] flex flex-row bottom-0 right-0 justify-end items-center space-x-8'>
                    <button className='bg-purple-200 px-[24px] py-[10px] rounded-md'> Cancel </button>
                    <button className='bg-black text-purple-200  px-[24px] py-[10px] rounded-md'> Save </button>
                </div>
            </div>
        </div>
    )
}

export default Complaint;