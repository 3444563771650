import react, {useState} from 'react';
import { Dropdown } from 'primereact/dropdown';
import ParentNav from './components/navigations';
import search from './assets/search.svg';
import file from './assets/file.svg';
import threedots from './assets/threebots.svg';


const ViewContract = () => {
    const [sorted, setSorted] = useState('');
    const [selectedCity, setSelectedCity] = useState(null);

    const cities = [
        { name: "Client ID" },
        { name: "Location" },
        { name: "Start Date" },
        { name: "Duration" }
    ];

    const handleSortbyChange = (e) => {
        setSorted(e.target.value);
    };


    const data = [
        { clientId: '001', specification: 'Spec A', location: 'Accra, Ghana', startDate: '2023-01-01', duration: '6 months', timestamps: '123456789' },
        { clientId: '002', specification: 'Spec B', location: 'Kumasi, Ghana', startDate: '2023-02-01', duration: '8 months', timestamps: '987654321' },
        { clientId: '003', specification: 'Spec C', location: 'Kumasi, Ghana', startDate: '2023-01-01', duration: '10 months', timestamps: '123456789' },
        { clientId: '004', specification: 'Spec D', location: 'Accra, Ghana', startDate: '2023-02-01', duration: '7 months', timestamps: '987654321' },
        { clientId: '005', specification: 'Spec E', location: 'Takoradi, Ghana', startDate: '2023-02-01', duration: '9 months', timestamps: '987654321' }
    ];

    return (
        <div>
            {/* <ParentNav /> */}
            <div className='pt-[14vh] px-[50px]'>
                <text className="text-[25px] font-extrabold w-full bg-white"> Contracts </text>
                <div className='flex flex-row w-full'>
                    <div className="relative w-[40vw]">
                        <img src={ search } className="absolute top-3 left-1" />
                        <input type="text" className="w-[80%] h-[7vh] pl-10 rounded-[5px] border border-[#686868]" />
                    </div>
                    <div className="card flex justify-content-center w-[20vw]">
                        <Dropdown value={sorted} onChange={(e) => setSorted(e.value)} options={cities} optionLabel="name" 
                            placeholder="Sort by:" className="w-full md:w-14rem" />
                    </div>
                </div>
            </div>
            <div className='px-[50px]'>
                <table className="w-full bg-white border border-gray-300 mt-[10px] mb-[10vh]">
                    <thead className='bg-[#c0b4cc]'>
                        <tr>
                            <th className="py-2 px-4 border-b">Client ID</th>
                            <th className="py-2 px-4 border-b">Specification</th>
                            <th className="py-2 px-4 border-b">Location</th>
                            <th className="py-2 px-4 border-b">Start Date</th>
                            <th className="py-2 px-4 border-b">Duration</th>
                            <th className="py-2 px-4 border-b">Timestamps</th>
                            <th className="border-b"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => 
                        (
                        <tr key={index} className={index % 2 === 0 ? 'bg-[#fff]' : 'bg-[#e0d4e4]'}>
                            <td className="py-2 px-4 text-center border-b">{item.clientId}</td>
                            <td className="flex justify-center py-2 border-b"><img src={file} /></td>
                            <td className="py-2 px-4 text-center border-b">{item.location}</td>
                            <td className="py-2 px-4 text-center border-b">{item.startDate}</td>
                            <td className="py-2 px-4 text-center border-b">{item.duration}</td>
                            <td className="py-2 px-4 text-center border-b">{item.timestamps}</td>
                            <td className="py-2 w-7 text-center border-b"> <button> <img src={ threedots } /> </button> </td>

                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div id='footer' className=' px-[30px] h-[60px] flex flex-row w-full fixed bottom-0 justify-between items-center space-x-8 bg-black'>
                <button className='bg-[#CEB0FF] px-[24px] py-[10px] rounded-md'> Previous </button>
                <button className='bg-[#CEB0FF] px-[16px] py-[10px] rounded-md'> 1 </button>
                <button className='bg-[#CEB0FF] text-black  px-[24px] py-[10px] rounded-md'> Next </button>
            </div>
        </div>
    )
}


export default ViewContract;